import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'כוכבי השעה והמזלות';
  
  constructor(private metaService: Meta) {}

  ngOnInit(): void {
    this.metaService.addTag( {name: "viewport", content: "width=device-width, initial-scale=1.0"})
    const content = "אותות השמים ההלכות והנהגות בני האדם והטבע ותכונותיהם ומאורעות הנגזרים מהם ";
    this.metaService.addTag( {itemprop: "name", content});
    this.metaService.addTag( {property: "og:title", content});
    this.metaService.addTag({ httpEquiv: "content-language", content: "he"});
    this.metaService.addTag({name: "description", content: ""});
  }
}
