<div class="row">
    <div class="col-sm-0 col-md-2 col-0"></div>
    <div class="col-sm-12 col-md-8 col-12">
        <div class="row">
            <table class="table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" colspan="3">{{planet.name}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td dir="rtl" class="left-td" colspan="2">{{planet.humanTrait}}</td>
                        <td dir="rtl" class="right-td">באדם</td>
                    </tr>
                    <tr>
                        <td dir="rtl" class="left-td" colspan="2">{{planet.rulingOn}}</td>
                        <td dir="rtl" class="right-td">מושל</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
