<div class="framediv main-div">
    <div class="row navdiv">
        <div class="col-sm-0 col-md-1"></div>
        <div class="col-sm-12 col-md-10">
            <ul class="nav nav-pills justify-content-center">
                <li class="nav-item">
                  <a class="nav-link" 
                  routerLink="/seasons"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">איסור שתית מים</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" 
                  routerLink="/seasons/forecast"
                  routerLinkActive="active">תחזית התקופה</a>
                </li>
              </ul>    
        </div>    
    </div>
  
    <app-local-watch></app-local-watch>

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center">{{header}}</h4>
        </div>
    </div>
    
    <!-- <app-address-latitude ></app-address-latitude> -->
    <router-outlet></router-outlet>
</div>
