import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { YMDs } from 'src/app/classes/hebrew-calculator';
import { WeekNightDay } from '../week-hour/week-hour.component';

@Component({
  selector: 'app-week-day-night',
  templateUrl: './week-day-night.component.html',
  styleUrls: ['./week-day-night.component.css']
})
export class WeekDayNightComponent implements OnInit,  OnChanges{
  @Input() hebrewDate!: YMDs;
  @Input() weekNightDay: WeekNightDay = {
    dayString: " ",
    night: [],
    day: [],
    daySequence: -1
  }

  isDayOpen = false;
  isNightOpen = false;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.hebrewDate === undefined) {
      return;
    }
    let wDay = this.hebrewDate.wDays;
    if (wDay === 0) {
      wDay = 7;
    }

    if (wDay === this.weekNightDay.daySequence) {

      const hour = this.hebrewDate.dayRemain.timeUnit.hours;
      if (hour / 12 < 1) {
        this.isNightOpen = true;
        this.isDayOpen = false;
      }
      else {
        this.isDayOpen = true;
        this.isNightOpen = false;
      }
    }
    else {
      this.isNightOpen = this.isDayOpen = false;
    }
    
  }

  ngOnInit(): void {
  }

  onNightClick(){
    this.isNightOpen = !this.isNightOpen;
  }

  onDayClick(){
    this.isDayOpen = !this.isDayOpen;
  }

}
