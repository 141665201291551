    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center"> תקופות ב - {{address}}</h4>
            <p dir="rtl" class="text-center">זמני איסור שתית מים הם לפי השעון שלי לכל תקופה ותקופה</p>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h5 dir="rtl" class="text-center" [class]="selectedSeason.className">התקופה הקרובה תקופת {{selectesSeasonName}} שנת {{selectedSeason.hebrewDate.years}}</h5>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="selectedSeason" [latitudeTotimezone]="latitude"></app-heb-units>
    
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center" [class]="seasions.tamuz.className">תקופת תמוז שנת {{seasions.tamuz.hebrewDate.years}}</h4>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="seasions.tamuz" [latitudeTotimezone]="latitude"></app-heb-units>
    
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center" [class]="seasions.tishrei.className">תקופת תשרי שנת {{seasions.tishrei.hebrewDate.years}}</h4>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="seasions.tishrei" [latitudeTotimezone]="latitude"></app-heb-units>

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center" [class]="seasions.tevet.className">תקופת טבת שנת {{seasions.tevet.hebrewDate.years}}</h4>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="seasions.tevet" [latitudeTotimezone]="latitude"></app-heb-units>

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center" [class]="seasions.nissan.className">תקופת ניסן שנת {{seasions.nissan.hebrewDate.years}}</h4>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="seasions.nissan" [latitudeTotimezone]="latitude"></app-heb-units>

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
            <h4 dir="rtl" class="text-center" [class]="seasions.tamuzNext.className">תקופת תמוז שנת {{seasions.tamuzNext.hebrewDate.years}}</h4>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="seasions.tamuzNext" [latitudeTotimezone]="latitude"></app-heb-units>

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
          <h4 dir="rtl" class="text-center" [class]="seasions.tishreiNext.className">תקופת תשרי שנת {{seasions.tishreiNext.hebrewDate.years}}</h4>
        </div>
    </div>
    
    <app-heb-units [hebrewDate]="seasions.tishreiNext" [latitudeTotimezone]="latitude"></app-heb-units>
