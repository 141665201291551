import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { HebDatetimeComponent } from './location-datetime/heb-datetime/heb-datetime.component';
import { JerusalemDatetimeComponent } from './location-datetime/jerusalem-datetime/jerusalem-datetime.component';
import { LocalDatetimeComponent } from './location-datetime/local-datetime/local-datetime.component';
import { MonthsDirective } from './directives/months.directive';
import { RulingPlanetsComponent } from './location-datetime/ruling-planets/ruling-planets.component';
import { CookieService } from 'ngx-cookie-service';
import { OverviewComponent } from './location-datetime/overview/overview.component';
import { DaysHoursComponent } from './location-datetime/days-hours/days-hours.component';
import { AboutComponent } from './about/about.component';
import { WeekDaysComponent } from './location-datetime/local-datetime/week-days/week-days.component';
import { WeekHoursComponent } from './location-datetime/local-datetime/week-hours/week-hours.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LocalWatchComponent } from './location-datetime/local-watch/local-watch.component';
import { PlanetsHoursComponent } from './location-datetime/local-datetime/planets-hours/planets-hours.component';
import { PlanetComponent } from './location-datetime/local-datetime/planets-hours/planet/planet.component';
import { MolladComponent } from './location-datetime/jerusalem-datetime/mollad/mollad.component';
import { HebUnitsComponent } from './location-datetime/heb-datetime/heb-units/heb-units.component';
import { AddressLatitudeComponent } from './location-datetime/address-latitude/address-latitude.component';
import { WeekDayComponent } from './location-datetime/local-datetime/week-days/week-day/week-day.component';
import { WeekDayNightComponent } from './location-datetime/local-datetime/week-hours/week-day-night/week-day-night.component';
import { WeekHourComponent } from './location-datetime/local-datetime/week-hours/week-hour/week-hour.component';
import { FooterComponent } from './footer/footer.component';
import { SeasionsComponent } from './location-datetime/seasions/seasons.component';
import { SeasionWaterComponent } from './location-datetime/seasions/season-latitude/season-water/seasion-water.component';
import { SeasonLatitudeComponent } from './location-datetime/seasions/season-latitude/season-latitude.component';
import { SeasonsForecastComponent } from './location-datetime/seasions/season-latitude/seasions-forecast/seasons-forecast.component';
import { SeasonForecastComponent } from './location-datetime/seasions/season-latitude/seasions-forecast/season-forecast/season-forecast.component';
import { PlanetEffectComponent } from './location-datetime/seasions/season-latitude/seasions-forecast/planet-effect/planet-effect.component';
import { FindPlanetZodiacComponent } from './find-planet-zodiac/find-planet-zodiac.component';
import { HebrewCalenderComponent } from './hebrew-calender/hebrew-calender.component';


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    HebDatetimeComponent,
    JerusalemDatetimeComponent,
    LocalDatetimeComponent,
    MonthsDirective,
    RulingPlanetsComponent,
    OverviewComponent,
    DaysHoursComponent,
    AboutComponent,
    WeekDaysComponent,
    WeekHoursComponent,
    ErrorPageComponent,
    LocalWatchComponent,
    PlanetsHoursComponent,
    PlanetComponent,
    MolladComponent,
    HebUnitsComponent,
    AddressLatitudeComponent,
    WeekDayComponent,
    WeekDayNightComponent,
    WeekHourComponent,
    FooterComponent,
    SeasionsComponent,
    SeasionWaterComponent,
    SeasonLatitudeComponent,
    SeasonsForecastComponent,
    SeasonForecastComponent,
    PlanetEffectComponent,
    FindPlanetZodiacComponent,
    HebrewCalenderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [CookieService, Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
