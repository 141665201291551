<nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow mb-3">
    <a class="navbar-brand" routerLink="/">חוקות שמים</a>
    <button class="navbar-toggler btn-sm" type="button" data-toggle="collapse" 
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target=".navbar-collapse"
    aria-label="Toggle navigation"
    [attr.aria-expanded]="isExpanded"
    (click)="toggle()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav"
      [ngClass]="{ show: isExpanded }">


      <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" 
          role="button" id="dropdownMenuLink" data-toggle="dropdown" 
          aria-haspopup="true"
          [attr.aria-expanded]="isOverviewExpanded"          
          (click)="toggelOverview()">פרקים</button>     

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink"
        [ngClass]="{ show: isOverviewExpanded }">
          <a class="dropdown-item" routerLink="/ruling-planets"
            (click)="toggelOverview()"
            routerLinkActive="active">כוכבים ומזלות</a>
          <a class="dropdown-item" routerLink="/jerusalem-datetime"
            (click)="toggelOverview()"
            routerLinkActive="active">מולדות וזמני ירושלים</a>
            <a class="dropdown-item" routerLink="/seasons"
            (click)="toggelOverview()"
            routerLinkActive="active">תקופות</a>
            <a class="dropdown-item" routerLink="/find-planet-zodiac"
            (click)="toggelOverview()"
            routerLinkActive="active">כוכב ומזל ע"פ שם</a>
          <a class="dropdown-item" routerLink="/about"
            (click)="toggelOverview()"
            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">אודות</a>
        </div>
      </div>
    </div>
  </nav>
