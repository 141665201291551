<div class="row">
    <div class="col-sm-0 col-md-2 col-0"></div>
    <div class="col-sm-12 col-md-8 col-12">
        <div class="row">
            <table class="table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" colspan="3">{{weekDay.wDayName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td dir="rtl" class="left-td" colspan="3">{{weekDay.description}}</td>
                    </tr>
                    <tr *ngFor="let planetGroup of weekDay.planets"> 
                        <td dir="rtl" class="left-td" colspan="2">{{planetGroup.description}}</td>
                        <td [class]="planetGroup.planetsClass" dir="rtl" class="right-td">{{planetGroup.planetsNames}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
