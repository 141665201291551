// import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { YMDs } from '../classes/hebrew-calculator';

// @Injectable({
//   providedIn: 'root'
// })
export class DatetimeNotifyService {
  date = new Subject<Date>();
  hebrewDateTime = new Subject<YMDs>();
  constructor() { }

  setDate(date: Date) {
    this.date.next(date);
  }

  setHebrewDate(ymds: YMDs) {
    this.hebrewDateTime.next(ymds);
  }
  
}
