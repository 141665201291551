<app-address-latitude></app-address-latitude>
<!-- <app-hebrew-calender></app-hebrew-calender> -->
<div class="row">
    <div class="col-sm-0 col-md-1"></div>
    <div class="col-sm-12 col-md-10">
        <div class="row">
            <div class="col-sm-4">
                <button type="button" dir="rtl" (click)="minus10()">10- שנים</button>
                <button type="button" dir="rtl" (click)="minusYear()">1- שנה</button>
                <button type="button" dir="rtl" (click)="minusMonth()">1- חודש</button>
                <button type="button" dir="rtl" (click)="minusDay()">1- יום</button>
            </div>
            <div class="col-sm-4">
                <p class="text-center bg-danger" dir="rtl">{{hebrewDate.hebrewDate.years}} {{hebrewDate.monthDayName}} {{hebrewDate.monthName}} יום {{hebrewDate.wDayName}}</p>
            </div>
            <div class="col-sm-4">
                <button type="button" dir="rtl" (click)="plus10()">10+ שנים</button>
                <button type="button" dir="rtl" (click)="plusYear()">1+ שנה</button>
                <button type="button" dir="rtl" (click)="plusMonth()">1+ חודש</button>
                <button type="button" dir="rtl" (click)="plusDay()">1+ יום</button>                
            </div>
        </div>
    </div>
</div>

