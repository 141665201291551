<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <table class="table table-sm">
            <thead>
                <tr class="table-primary">
                    <th>רגעים</th>
                    <th>חלקים</th>
                    <th>שעה</th>
                    <th>יום</th>
                    <th>יום-ח</th>
                    <th>חודש</th>
                    <th>שנה</th>
                    <th>שעון</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-danger">
                    <td>{{hebrewDate.hebrewDate.dayRemain.timeUnit.regaim | number:'2.0'}}</td>
                    <td>{{hebrewDate.hebrewDate.dayRemain.timeUnit.halakim | number:'2.0'}}</td>
                    <td>{{hebrewDate.hebrewDate.dayRemain.timeUnit.hours | number:'2.0'}}</td>
                    <td>{{hebrewDate.wDayName}}</td>
                    <td>{{hebrewDate.monthDayName}}</td>
                    <td>{{hebrewDate.monthName}}</td>
                    <td>{{hebrewDate.hebrewDate.years}}</td>
                    <td>עברי</td>
                </tr>
                <tr class="table-danger">
                    <td>{{loazyDate.hebrewDate.dayRemain.timeUnit.regaim | number:'2.0'}}</td>
                    <td>{{loazyDate.minutsHalakim}}</td>
                    <td>{{loazyDate.hebrewDate.dayRemain.timeUnit.hours | number:'2.0'}}</td>
                    <td>{{loazyDate.wDayName}}</td>
                    <td>{{loazyDate.monthDayName}}</td>
                    <td>{{loazyDate.monthName}}</td>
                    <td>{{loazyDate.hebrewDate.years}}</td>
                    <td>לועזי</td>
                </tr>
                <tr class="table-danger" *ngIf="isLatitude">
                    <td>{{zoneDate.hebrewDate.dayRemain.timeUnit.regaim | number:'2.0'}}</td>
                    <td>{{zoneDate.minutsHalakim}}</td>
                    <td>{{zoneDate.hebrewDate.dayRemain.timeUnit.hours | number:'2.0'}}</td>
                    <td>{{zoneDate.wDayName}}</td>
                    <td>{{zoneDate.monthDayName}}</td>
                    <td>{{zoneDate.monthName}}</td>
                    <td>{{zoneDate.hebrewDate.years}}</td>
                    <td>שלי</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

