<div class="framediv main-div">
    <p dir="rtl" class="text-center">עדיין לא מיועד לשימוש.</p>
    <app-hebrew-calender></app-hebrew-calender>
    <div class="row">
        <div class="col-sm-0 col-md-1"></div>
        <div class="col-sm-12 col-md-10">
            <a dir="rtl" href="http://kiddushhachodesh.net/Chapter9#chap9-5" target="_blank"><p class="btn-outline-primary p">חישוב המזל השולט כפי שמובא בפרקי דרבי אליעזר מיוסד על פי תקופת שמואל אשר העיקר שאנו מחשבים מימנו הוא מתקופת ניסן הראשונה בשנה הראשונה ליצירה היא שנת תוהו. וכפ שמובא בהלכות קידוש הודש לרמב"ם.</p></a>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-0 col-md-1"></div>
        <div class="col-sm-12 col-md-10">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th colspan="2">מזלות השולטים על פי פרקי דרבי אליעזר פרק ו</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="td1">{{zodiacDeg}}</td>
                        <td class="td1">מעלות השמש ליום זה</td>
                    </tr>
                    <tr>
                        <td class="td1">{{zodiacInYear}}</td>
                        <td class="td1">מזל בשנה</td>
                    </tr>
                    <tr>
                        <td class="td1">{{zodiacInMonth}}</td>
                        <td class="td1">מזל בחודש</td>
                    </tr>
                </tbody>
            </table>
       </div>
    </div>
    <div class="row">
        <div class="col-sm-0 col-md-1"></div>
        <div class="col-sm-12 col-md-10">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th colspan="2">מזלות החודש {{zodiacInYear}}</th>
                    </tr>
                    <tr>
                        <th>תחילת זמן השליטה</th>
                        <th>מזל</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let z of dateZodiacs">
                        <td class="td2">{{z.date}}</td>
                        <td class="td2">{{z.zodiac}}</td>
                    </tr>
                </tbody>
            </table>
       </div>
    </div>
    <div class="row">
        <div class="col-sm-0 col-md-1"></div>
        <div class="col-sm-12 col-md-10">
            <h5 dir="rtl" class="text-center">כוכב השעה והמזל בשעת הלידה</h5>
            <p dir="rtl" class="text-center p">יש לכתוב את השם במדוייק אחרת יתן תוצאות שגויות, כגון שמות שההיגוי שלהם מסתיים בו"ו חולמית אך נכתבים בה"א חולמית, וכן שמות כמו מנדל שבמקורו נכתב מנדעל, וכן שם חיבה כגון מושקא יש לכתוב מוסיה וכן רבים אחרים יש להקפיד על כל אלה. וכן מי שלא נולד בלידה טבעית לחלוטין יהיו פערים בין תאריך הלידה ולשם. לכן יש להתישב הרבה באופן כתיבת השם כדי יעלה יפה עם כוכב השעה והמזל השולט בזמן הלידה.</p>
            <div>
                <input type="text" class="form-control btn-outline-warning alert-primary" 
                aria-label="Small" 
                aria-describedby="inputGroup-sizing-sm"
                [(ngModel)]="nameAndMother" name="nameAndMother"
                placeholder="כתוב שם ושם האמא">    
            </div>
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th colspan="2">מציאת כוכב השעה והמזל השולט בלידה על פי השם ושם האם</th>
                    </tr>
                    <tr>
                        <th colspan="2">שיטה המיוחסת לרבי סעדיה הגאון</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="td1">{{result}}</td>
                        <td class="td1">גימטרית השם</td>
                    </tr>
                    <tr>
                        <td class="td1">{{planet}}</td>
                        <td class="td1"v>כוכב השעה</td>
                    </tr>
                    <tr>
                        <td class="td1">{{zodiac}}</td>
                        <td class="td1">מזל השולט</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
