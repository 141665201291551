import { Component, Input, OnInit } from '@angular/core';

export interface IPlanet {
  name: string;
  humanTrait: string;
  rulingOn: string;
}

@Component({
  selector: 'app-planet',
  templateUrl: './planet.component.html',
  styleUrls: ['./planet.component.css']
})
export class PlanetComponent implements OnInit {
@Input() planet!: IPlanet;
  constructor() { }

  ngOnInit(): void {
  }

}
