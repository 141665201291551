import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DaysPast, getDateFromDaysPast, intDivideRemain, roundAndRemain, TimeAccessor, YMDs } from 'src/app/classes/hebrew-calculator';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { getDateString, isDST } from 'src/app/utils/hebrew-units-converters';
import { WeekNightDay } from './week-hour/week-hour.component';


@Component({
  selector: 'app-week-hours',
  templateUrl: './week-hours.component.html',
  styleUrls: ['./week-hours.component.css']
})
export class WeekHoursComponent implements OnInit {
  weekNightsDays: WeekNightDay[] = [
    {
      daySequence: 1,
      dayString: "ראשון",
      night: [
        {
          hourCharacteristics: "כוכב לכל דבר יפה.",
          birthCharacter: undefined,
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה להכניס את הכלה יפה.",
          birthCharacter: undefined,
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק לכל דבר יפה.",
          birthCharacter: undefined,
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים כן הוא.",
          birthCharacter: undefined,
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: " חמה בטילה.",
          birthCharacter: undefined,
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה כן הוא.",
          birthCharacter: undefined,
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב לכל דבר יפה.",
          birthCharacter: undefined,
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה כן הוא.",
          birthCharacter: undefined,
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק לדרך יפה.",
          birthCharacter: undefined,
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }
      ],
      day: [
        {
          hourCharacteristics: "חמה יפה לבית להכניס, ובגד חדש ללבוש, פרי חדש לאכול, ויין חדש לשתות, ולפני מלך לכנוס, ודברים לאמר לבלום יפה, ולמקח וממכר קשה.",
          birthCharacter: undefined,
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected"
        },
        {
          hourCharacteristics: "נוגה למקח וממכר יפה, בעיר אשה לארוס, ובגד חדש ללבוש, וכסף וזהב להתקשט, ולרחוץ במים. ובית חדש לכנוס וכתר לקשור וסוס לרכב ולצאת למלחמה, ולעשות רפואה יפה. אצל גדול לקרב אינו יפה.",
          birthCharacter: "ומי שנולד בה יעלה לגדולה, ואחר כך ירד מגדולתו, ויהיה חכם בתורה",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected"
        },
        {
          hourCharacteristics: "כוכב, לדרך ולבינין קשה.",
          birthCharacter: "בעל נכסים יהיה.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected"
        },
        {
          hourCharacteristics: "לבנה, למקח וממכר יפה. ולעשות בה שותפות קשה, ולברוח ולאובד.",
          birthCharacter: undefined,
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected"
        },
        {
          hourCharacteristics: "שבתי, לדרך רחוקה להלוך, ולעשות שותפות, ולהוציא זרע לשדה יפה.",
          birthCharacter: "עני וחסיד יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected"
        },
        {
          hourCharacteristics: "צדק, למקח וממכר ולדרך קשה.",
          birthCharacter: "חכם יהיה",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected"
        },
        {
          hourCharacteristics: "מאדים, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected"
        },
        {
          hourCharacteristics: "חמה כן היא.",
          birthCharacter: "עני ואביון ואנין הדעת יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected"
        },
        {
          hourCharacteristics: "נוגה, למקח ולממכר ולהכניס כלה יפה.",
          birthCharacter: "חכם ופקח יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected"
        },
        {
          hourCharacteristics: "כוכב כן היא",
          birthCharacter: undefined,
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected"
        },
        {
          hourCharacteristics: "לבנה, יפה להכניס כלה.",
          birthCharacter: "חכם ופקח יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected"
        },
        {
          hourCharacteristics: "שבתי, בטילה היא",
          birthCharacter: "בטלן יהיה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected"
        },
        
      ]
    },
    {
      daySequence: 2,
      dayString: "שני",
      night: [
        {
          hourCharacteristics: "צדק, לדרך ולהכניס כלה יפה.",
          birthCharacter: "יפה מראה וחכם.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, אצל אשה לא תקרב, לא תקח ולא תמכור.",
          birthCharacter: "עני יהיה, וימות תינוק.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, לכל דבר יפה.",
          birthCharacter: "ערום יהיה ורב דיע, וסופו יהרג בחרב.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, למקח וממכר ולהכנסת כלה יפה",
          birthCharacter: "יהיה קושר ויהרג.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לקרב לאשה יפה.",
          birthCharacter: "חכם יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, לגניבה יפה ומקח וממכר.",
          birthCharacter: "מכל דרכים ייהנה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, לגניבה יפה.",
          birthCharacter: "מפזר ולא מכניס, חכמה לא יקנה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לכל דבר יפה.",
          birthCharacter: "יאסוף הון ולא לו.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לכל דבר רע.",
          birthCharacter: "אדום ואיש רע יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה בטילה היא.",
          birthCharacter: "כשהוא תינוק ימות.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר טוב.",
          birthCharacter: "חכם ומכוסה יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לכל דבר יפה.",
          birthCharacter: "יעלה לראש.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ],
      day: [
        {
          hourCharacteristics: "לבנה, לכל דבר רע.",
          birthCharacter: "יהיה בו מעט שטות.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, כל העושה בו כלום חסרון ימצא.",
          birthCharacter: "חסר דעה",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לדרך ולארוס אישה יפה.",
          birthCharacter: "חכם יהיה, והורגין אותו.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לרפואה יפה.",
          birthCharacter: "אבוד יאבד.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, לכל דבר יפה.",
          birthCharacter: "חכם ושפל רוח יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר יפה.",
          birthCharacter: "קשה הוא ורע.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, למכור ולקנות ושותפות טוב.",
          birthCharacter: undefined,
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה בטילה היא.",
          birthCharacter: "ירא שמים ושפל רוח ועני יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "אדם גדול ועשיר יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, למקח וממכר יפה.",
          birthCharacter: "יחיה.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, בטילה היא.",
          birthCharacter: "רופה אומן ובקיא יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, לבית לכנוס, למקח וממכר יפה.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        },

      ]
    },
    {
      daySequence: 3,
      dayString: "שלישי",
      night: [
        {
          hourCharacteristics: "נוגה, להכניס לחופה יפה.",
          birthCharacter: "שעיר יאחז אותו.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, חתן לקרב אל אשה יפה, ומבית לבית לילך יפה.",
          birthCharacter: "מחריב הרבים יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, חתן אצל כלה לקרב יפה.",
          birthCharacter: "אלמנה יקח.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "מצליח יהיה וגנב.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לדרך יפה, והגונב יאבד.",
          birthCharacter: "בעל אלמנה יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, בטילה היא.",
          birthCharacter: "עני יהיה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: "איש קשה ורע מעללים יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, בטילה היא.",
          birthCharacter: "בטלן יהיה ובינקותו ימות.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לדרך רחוקה יפה, ימצא טוב ויחזור לביתו.",
          birthCharacter: "מתפרנס.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה לכל דבר יפה, והיוצא לדרך יחזור לשלום לביתו.",
          birthCharacter: undefined,
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, לכל דבר טוב.",
          birthCharacter: "חכם וחסיד יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לכל דבר טוב.",
          birthCharacter: "חכם וחסיד יהיה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ],
      day: [
        {
          hourCharacteristics: "מאדים, לרופא יפה בלבו.",
          birthCharacter: "יפול בשביל רוח.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: "יעף בכח יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, למקח וממכר יפה.",
          birthCharacter: "גבור יהיה.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לקנות עבדים ושפחות ובהמות יפה, לדרך רע.",
          birthCharacter: "ימיו מועטין.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי בטילה היא.",
          birthCharacter: "בטלן יהיה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, בטילה היא.",
          birthCharacter: "עני יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, בטילה היא.",
          birthCharacter: "בעל מום יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, כן הוא.",
          birthCharacter: undefined,
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, יפה לכל דבר.",
          birthCharacter: "רופא מלכים יהיה.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, בטילה היא.",
          birthCharacter: "לא בעל טוב ולא בעל רע.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא",
          birthCharacter: "גנב יהיה ומצליח.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ]
    },
    {
      daySequence: 4,
      dayString: "רביעי",
      night: [
        {
          hourCharacteristics: "שבתי, ההולך לדרך לא יצליח.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לכל דבר יפה.",
          birthCharacter: "מפרנס אחרים.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, אל אשה לא תקרב.",
          birthCharacter: "יקבר בקבר אבותיו.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: "בעל מום יהיה.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לשותפות יפה.",
          birthCharacter: "רופא וחסר דיעה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לכל דבר יפה.",
          birthCharacter: "גנב ומצליח יהיה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, לדרך יפה, וליצא מבית לבית.",
          birthCharacter: "בעל אמונה ועניו יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "סומא יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לדרך יפה.",
          birthCharacter: "בעל אמונה ובעל סחורה יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, בטילה היא.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: "יהרג.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לדרך קשה.",
          birthCharacter: "יפה מראה יהיה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ],
      day: [
        {
          hourCharacteristics: "כוכב, למקח וממכר ולשותפות יפה.",
          birthCharacter: "חכם ופיקח יהיה.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, קנה ואל תמכור.",
          birthCharacter: "עני יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "חעם יהיה לא יקום בחוכמתו.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לכל דבר יפה.",
          birthCharacter: "בעל אומנות ויוסיף לו חכמה.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לכל דבר רע.",
          birthCharacter: "מכשף יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, אצל אדם גדול לקרב יפה.",
          birthCharacter: undefined,
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר יפה.",
          birthCharacter: "למקח וממכר, וללמד תינוקות ולאומנות יפה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, למקח וממכר יפה.",
          birthCharacter: "בעל נכסים יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא.",
          birthCharacter: "בעל יסורים יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לדרך ולבינין יפה.",
          birthCharacter: "יפה תואר יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לכל דבר קשה.",
          birthCharacter: "הורג נפשות יהיה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ]
    },
    {
      daySequence: 5,
      dayString: "חמישי",
      night: [
        {
          hourCharacteristics: "חמה, לכל דבר יפה.",
          birthCharacter: "מעט יהיה פרנסתו.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר יפה.",
          birthCharacter: "רופה מלכים יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לכל דבר יפה.",
          birthCharacter: "חכם ופקח יהיה, והלוך אצל אדם גדול או אצל מלך לא יהנה מהם.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, לכל דבר יפה.",
          birthCharacter: "יהיה לראש.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, כל מה שיעשה בסתר יתגלה לכל.",
          birthCharacter: "כראוי יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לכל דבר יפה.",
          birthCharacter: "רב יהיה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, הגונב יתפש.",
          birthCharacter: "צייד יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: "בעל מום יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר יפה, ולישא אשה.",
          birthCharacter: "בעל חלק יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לדרך יפה, ולהלוך אצל אדם גדול.",
          birthCharacter: "עני וחסיד יהיה.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, לבינין ורפואה ולמקח וממכר יפה.",
          birthCharacter: "ילמד אומנות וחכמה הרבה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ],
      day: [
        {
          hourCharacteristics: "צדק, לדרך ולכל דבר יפה.",
          birthCharacter: "והנולד בה דיין יהיה.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים לרפואה יפה.",
          birthCharacter: "שופך דמים יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, סחורה בתוך העיר יפה.",
          birthCharacter: "חסיד יהיה.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לבוא אצל אדם גדול ולדרך יפה.",
          birthCharacter: "חסיד בדינו יהיה.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, בטילה היא.",
          birthCharacter: "לא יהיה בעל נפש.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, לכל דבבר יפה.",
          birthCharacter: "בקי וחכם יהיה ובעל יסורין.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, לדרך יפה.",
          birthCharacter: "מהלך בדרכים יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לדרך יפה.",
          birthCharacter: "כזבן יהיה.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לכל דבר קשה.",
          birthCharacter: "טבח או אומן יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, לדרך ולכל דבר קשה.",
          birthCharacter: "חלש בגופו יהיה.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר יפה.",
          birthCharacter: "זכאי בדינו יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לדרך ולסחורה ולבוא בדין יפה.",
          birthCharacter: "עשיר יהיה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ]
    },
    {
      daySequence: 6,
      dayString: "שישי",
      night: [
        {
          hourCharacteristics: "לבנה, לכל דבר יפה.",
          birthCharacter: "בעל אמונה ועני יהיה.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "לא חכם ולא עשיר יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לכל דבר יפה.",
          birthCharacter: "גנב יהיה ומצליח ולא יתבזה.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לכל דבר קשה.",
          birthCharacter: "שופך דמים יהיה.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, לגנב קשה.",
          birthCharacter: "וכל הגונב יתפש.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה לכל דבר יפה למקח וממכר, ולקרב אל אשה.",
          birthCharacter: "נואף וזריז יהיה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לגניבה יפה, והגונב יאבד.",
          birthCharacter: "גנב יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, לדרך ולבוא לדין יפה.",
          birthCharacter: "בטלן יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, למקח וממכר יפה.",
          birthCharacter: "זריז ופקח יהיה.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים בטילה היא.",
          birthCharacter: "בעל מום יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, לכל דבר יפה.",
          birthCharacter: "לא יהיה בו מום.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ],
      day: [
        {
          hourCharacteristics: "נוגה, לכל דבר יפה.",
          birthCharacter: "סומא יהיה.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לכל דבר יפה.",
          birthCharacter: "פרנס יהיה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה",
          birthCharacter: "בעל דעת הוא.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: "מפרנס אחרים.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, ליכנס אצל מלכים יפה, ולמקח וממכר.",
          birthCharacter: "יפה תואר.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, רפואה יפה.",
          birthCharacter: "פרנס יהיה.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, למקח וממכר קשה.",
          birthCharacter: "פרנס יהיה.",
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, כן היא.",
          birthCharacter: undefined,
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, למקח וממכר יפה.",
          birthCharacter: "עשיר יהיה.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא.",
          birthCharacter: "בעל אמונה יהיה.",
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בתוך המדינות יפה.",
          birthCharacter: "חכם יהיה ויהרג.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, אצל כלה ליכנוס, לדרך יפה",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ]
    },
    {
      daySequence: 7,
      dayString: "שבת",
      night: [
        {
          hourCharacteristics: "מאדים, בטילה היא.",
          birthCharacter: "לא יחיה.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, כן היא.",
          birthCharacter: undefined,
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לכל דבר יפה.",
          birthCharacter: "חכם ופקח יהיה.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא, והגונב יצליח.",
          birthCharacter: "יצליח גנב.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא ואין כלום.",
          birthCharacter: undefined,
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, בטילה היא, ואין בה טוב ורע.",
          birthCharacter: undefined,
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, יפה לרפואה.",
          birthCharacter: "הורג אדם.",
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, לדרך ובינין יפה.",
          birthCharacter: "שופט אמת יהיה.",
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ],
      day: [
        {
          hourCharacteristics: "שבתי, לדרך ולבינין יפה",
          birthCharacter: "ימות.",
          sequentialHour: 1,
          sequentialLetter: "א",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לדרך ולבינין וליכנס אצל אדם גדול יפה.",
          birthCharacter: "ילקה.",
          sequentialHour: 2,
          sequentialLetter: "ב",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, לכל דבר יפה",
          birthCharacter: "יהיה לראש.",
          sequentialHour: 3,
          sequentialLetter: "ג",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בתוך העיר יפה, ולדרך לא תלך.",
          birthCharacter: "לא יצליח ובעל מום יהיה.",
          sequentialHour: 4,
          sequentialLetter: "ד",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, בטילה היא.",
          birthCharacter: "עשיר גדול יהיה.",
          sequentialHour: 5,
          sequentialLetter: "ה",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "כוכב, מקומו יפה.",
          birthCharacter: "בינקותו ימות.",
          sequentialHour: 6,
          sequentialLetter: "ו",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "לבנה, בטילה היא ואין כלום שם.",
          birthCharacter: undefined,
          sequentialHour: 7,
          sequentialLetter: "ז",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "שבתי, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 8,
          sequentialLetter: "ח",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "צדק, לקנות שדה ולנטוע נטיעות יפה.",
          birthCharacter: "עבד ימות.",
          sequentialHour: 9,
          sequentialLetter: "ט",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "מאדים, בטילה.",
          birthCharacter: undefined,
          sequentialHour: 10,
          sequentialLetter: "י",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "חמה, בטילה היא.",
          birthCharacter: undefined,
          sequentialHour: 11,
          sequentialLetter: "יא",
          className: "notSelected",
          localWatch: ""
        },
        {
          hourCharacteristics: "נוגה, לכל דבר יפה.",
          birthCharacter: "חכם ותמים יהיה.",
          sequentialHour: 12,
          sequentialLetter: "יב",
          className: "notSelected",
          localWatch: ""
        }

      ]
    }
  ];
  hebrewDate!: YMDs;
  lastHour = -1;
  date!: Date;  
  selectedDay: WeekNightDay = {
    dayString: " ",
    night: [],
    day: [],
    daySequence: -1
  };

  constructor(private datetimeNotify: DatetimeNotifyService,
    private titleService: Title,
    private metaService: MetaTagsService) { }

  ngOnInit(): void {
    this.metaService.updateDescription("התכונה המיוחדת של כוכב השעה בהתאם לשעה והיום שבו הוא שולט, ומזלו של האדם הנולד בשעה זאת על פי בעל הרוקח הרב אלעזר מגרמייזא.");
    this.titleService.setTitle("שעות השבוע");
    this.datetimeNotify.hebrewDateTime.subscribe((hebrewDate: YMDs) => {
      if (this.lastHour !== hebrewDate.dayRemain.timeUnit.hours) {
        this.lastHour = hebrewDate.dayRemain.timeUnit.hours;
      } else {
        return;
      }

      this.hebrewDate = hebrewDate;
      this.initHours();
    });

  }

  private getHourString(date: Date, day: number, hour: number): string {
    const hoursDST = isDST(date) ? -1 : 0;
    const newDate = new Date(date.getTime() + 3600000 * 24 * (day - 1) + (hour - 1 + hoursDST) * 3600000);
    return getDateString(newDate);
  }

  private initHours() {

    const daysDiff = this.getDaysDiffToStart();
    const start =  this.getFirstHourInWeek(daysDiff);

    for (const day of this.weekNightsDays) {
      for (let n of day.night) {
        n.localWatch = this.getHourString(start, day.daySequence, n.sequentialHour);
        n.className = "notSelected";
      }
      for (let d of day.day) {
        d.localWatch =  this.getHourString(start, day.daySequence, d.sequentialHour + 12);
        d.className = "notSelected";
      }
    }

    const hoursDST = isDST(new Date()) ? 1/24 : 0;

    const days = roundAndRemain(daysDiff + hoursDST);
    const hours = roundAndRemain(days.remain * 24);
    const dayOrNight = intDivideRemain(hours.round, 12);
    const selectedDay = this.weekNightsDays[days.round];
    let h = dayOrNight.remain;

    let selected: WeekNightDay = {
      day: [],
      daySequence: selectedDay.daySequence,
      dayString: selectedDay.dayString,
      night: []
    }
    
    if (dayOrNight.divided === 0) {
      selectedDay.night[h].className = "selected";
      selected.night.push(selectedDay.night[h]);
    } else {
      selectedDay.day[h].className = "selected";
      selected.day.push(selectedDay.day[h]);
    }

    this.selectedDay = selected;
  }

  private getFirstHourInWeek(daysDiff: number): Date {
    return new Date(new Date().getTime() - daysDiff * 3600000 * 24);
  }


  private getDaysDiffToStart() {
    let wDay = this.hebrewDate.wDays;
    if (wDay === 0) {
      wDay = 7;
    }
    const toSubtract = wDay - 1;
    const current = new DaysPast(this.hebrewDate).daysPastTimeAccessor;
    const sundayTs = current.minus(new TimeAccessor(toSubtract));
    const sundayYmds = getDateFromDaysPast(sundayTs);
    const sundayStart = new DaysPast(new YMDs(sundayYmds.years, sundayYmds.months, sundayYmds.mDays)).daysPastTimeAccessor;
    const daysDiff = current.floatTimeUnit - sundayStart.floatTimeUnit;
    return daysDiff;
  }
}
