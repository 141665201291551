import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { convertYMDsToTimeAccessor, fixedTo15, getDateFromDaysPast, intDivideRemain, TimeAccessor, YMDs } from '../classes/hebrew-calculator';
import { YearSeasons } from '../classes/season';
import { HebrewCalenderService } from '../services/hebrew-calender.service';
import { MetaTagsService } from '../services/meta-tags.service';
import { timeZoneToLatitudeCompensation } from '../utils/hebrew-location';
import { getFormatedHebrewDateTime, getRulingName, IFormatedHebrewDateTime } from '../utils/hebrew-units-converters';

export interface IDateZoadic{
  date: string;
  zodiac: string;
}

@Component({
  selector: 'app-find-planet-zodiac',
  templateUrl: './find-planet-zodiac.component.html',
  styleUrls: ['./find-planet-zodiac.component.css']
})
export class FindPlanetZodiacComponent implements OnInit, OnDestroy {
  private _nameAndMother = "";
  @Input() set nameAndMother(n: string) {
    this._nameAndMother = n;
    
    this.result = this.getResult();
    this.planet = this.getPlanet()
    this.zodiac = this.getZodiac();
    
  }

  get nameAndMother(){
    return this._nameAndMother;
  }

  private hebrewCalculatorSubscription!: Subscription;

  zodiacDeg = 0;
  zodiacInYear = "";
  zodiacInMonth = "";

  planet = "";
  zodiac = "";
  result = 0;

  dateZodiacs: IDateZoadic[] = [];

  constructor(private titleService: Title,
    private metaService: MetaTagsService,
    private hebrewCalender: HebrewCalenderService) { }

  ngOnDestroy(): void {
    this.hebrewCalculatorSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.metaService.updateDescription('מציאת כוכב השעה והמזל בשעת הלידה ע"פ שם ושם האם');
    this.titleService.setTitle("כוכב השעה והמזל בשעת הלידה");

    this.hebrewCalculatorSubscription = this.hebrewCalender.hebrewDateTime.subscribe((ymds: YMDs) => {
      this.setZodiac();
    });

  }

  readonly dayDeg = 360 / 365.6;
  readonly monthLength = (new TimeAccessor(30, 10, 540)).floatTimeUnit;

  private setZodiac() {
    const calanderYear = this.hebrewCalender.hebrewYMDs;
    const calanderTa = convertYMDsToTimeAccessor(calanderYear);

    // timeZoneToLatitudeCompensation(this.latitudeTotimezone, ta)
    

    const nissan = timeZoneToLatitudeCompensation(this.hebrewCalender.latitude, this.findNissan(calanderTa, calanderYear.years));
    const nissanFloat = nissan.floatTimeUnit;
    let zodiacDeg = ((calanderTa.floatTimeUnit - nissan.floatTimeUnit) * this.dayDeg) % 360;
    this.zodiacDeg = fixedTo15(zodiacDeg, 0);
    let deg = Math.floor(zodiacDeg / 30);
    this.zodiacInYear = this.degreeToZodiac(deg);

    const monthDeg = Math.floor((((zodiacDeg % 30) * this.monthLength / 30) / 2.5) % 12);
    
    this.zodiacInMonth = this.degreeToZodiac(monthDeg, deg);

    const thisZoadicStart = intDivideRemain(zodiacDeg, 30);

    const starttime = new TimeAccessor(nissanFloat + thisZoadicStart.divided * this.monthLength);
    
   let dateZodiacs: IDateZoadic[] = [];

    const plus = new TimeAccessor(2, 12);

    for (let i = 0; i < 13; i++) {
      const subDate = starttime.plus(plus.multiply(i, false), false);
      const date = this.getStartSubString(getFormatedHebrewDateTime(getDateFromDaysPast(subDate)));
      const zodiac = this.degreeToZodiac(i, deg);
      dateZodiacs.push({date, zodiac});
    }

    this.dateZodiacs = dateZodiacs;    

  }

  private getStartSubString(f: IFormatedHebrewDateTime): string {
    const dayHeb = "יום";
    const hourHeb = "שעה";
    
    return `${f.monthDayName} ${f.monthName} ${dayHeb} ${f.wDayName} ${hourHeb} ${f.hebrewDate.dayRemain.timeUnit.hours} ${f.minutsHalakim}`;
  }

  private degreeToZodiac(m: number, sub = 0): string {

    m = (m + sub) % 12;

    switch (m) {
      case 0:        
        return "טלה";
      case 1:        
        return "שור";
      case 2:        
        return "תאומים";
      case 3:        
        return "סרטן";
      case 4:        
        return "אריה";
      case 5:        
        return "בתולה";
      case 6:        
        return "מאזנים";
      case 7:        
        return "עקרב";
      case 8:        
        return "קשת";
      case 9:        
        return "גדי";
      case 10:        
        return "דלי";
      case 11:        
        return "דגים";
    
      default:
        return "שגיאה";
    }
  }

  private findNissan(calanderTa: TimeAccessor, years: number): TimeAccessor {
    const firstNissan = YearSeasons.getFirstNissan();

    let thisNissan:TimeAccessor;
    do {
      years--;
      thisNissan = firstNissan.plus(YearSeasons.yearPeriod.multiply(years, false), false);

    } while (thisNissan.floatTimeUnit > calanderTa.floatTimeUnit);

    return thisNissan
  }

  getPlanet(): string {
    const planet = (this.result + 4) % 7
    return getRulingName(planet);
  }

  getResult(): number {
    const array = [...this._nameAndMother];

    let sum = 0;
    for (const c of array) {
      sum += this.stringToNumber(c);
    }

    return sum;
  }

  getZodiac(): string {
    const zodiac = this.result % 12;

    switch (zodiac) {
      case 1:        
        return "סרטן";
      case 2:        
        return "אריה";
      case 3:        
        return "בתולה";
      case 4:        
        return "טלה";
      case 5:        
        return "שור";
      case 6:        
        return "תאומים";
      case 7:        
        return "מאזנים";
      case 8:        
        return "עקרב";
      case 9:        
        return "קשת";
      case 10:        
        return "גדי";
      case 11:        
        return "דלי";
      case 0:        
        return "דגים";
    
      default:
        return "שגיאה";
    }
  }

  stringToNumber(c:string):number {
    switch (c) {
      case "א":        
        return 1;
      case "ב":        
        return 2;
      case "ג":        
        return 3;
      case "ד":        
        return 4;
      case "ה":        
        return 5;
      case "ו":        
        return 6;
      case "ז":        
        return 7;
      case "ח":        
        return 8;
      case "ט":        
        return 9;
      case "י":        
        return 10;
      case "כ":        
      case "ך":        
        return 20;
      case "ל":        
        return 30;
      case "מ":        
      case "ם":        
        return 40;
      case "נ":        
        return 50;
      case "ן":        
        return 50;
      case "ס":        
        return 60;
      case "ע":        
        return 70;
      case "פ":        
      case "ף":        
        return 80;
      case "צ":        
      case "ץ":        
        return 90;
      case "ק":        
        return 100;
      case "ר":        
        return 200;
      case "ש":        
        return 300;
      case "ת":        
        return 400;
    
      default:
        return 0;
    }
  }

  
}
