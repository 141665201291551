<div class="row">
    <div class="col-2"></div>
    <div class="input-group input-group-sm mb-3 col-8">
        <input type="text" class="form-control btn-outline-warning alert-primary" 
            aria-label="Small" 
            aria-describedby="inputGroup-sizing-sm"
            [(ngModel)]="address" name="address" 
            (ngModelChange)="inputChanged()" 
            (keydown.enter)="inputEnter()">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">מקום או כתובת</span>
        </div>
    </div>      
</div>
<p class="text-center" style="color: red;" *ngIf="isError">נסה חיפוש מקום אחר</p>
<div *ngIf="!isError">
    <div class="row">
        <div class="col-md-3 col-sm-1 col-lg-4"></div>
        <div class="col-md-6 col-sm-10 col-lg-4">
            <table class="table table-sm">
                <thead>
                    <tr class="bg-warning text-center">
                        <th class="bg-danger">{{latitude}}</th>
                        <th>קו רוחב</th>
                    </tr>
                </thead>
            </table>       
        </div>
    </div>
</div>
