import { Component, OnInit } from '@angular/core';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';

@Component({
  selector: 'app-days-hours',
  templateUrl: './days-hours.component.html',
  styleUrls: ['./days-hours.component.css'],
  providers: [DatetimeNotifyService]
})
export class DaysHoursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
