import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { LocalTimeService } from 'src/app/services/local-time.service';

@Component({
  selector: 'app-address-latitude',
  templateUrl: './address-latitude.component.html',
  styleUrls: ['./address-latitude.component.css']
})
export class AddressLatitudeComponent implements OnInit, OnDestroy {
  @Output() addressEvent = new EventEmitter<string>();
  private _address!: string;
  set address(value: string) {
    this._address = value;
    this.addressEvent.emit(value);
  }

  get address(): string {
    return this._address;
  }
  interval!: number;
  private latSubscription!: Subscription;
  private isErrorSubscription!: Subscription;
  private addressSubscription!: Subscription;
  private _isError = false;
  @Input() set isError(value: boolean) {
    this._isError = value;
    this.isErrorEvent.emit(value);
  }

  get isError(): boolean {
    return this._isError;
  }

  @Output() isErrorEvent = new EventEmitter<boolean>();
  @Output() latitudeEvent = new EventEmitter<number>();
  latitude = 0;
  private cookieName = "lastAddress";

  constructor(private localTime: LocalTimeService,
    private cookieService: CookieService) { }
  ngOnDestroy(): void {
    this.latSubscription.unsubscribe();
    this.isErrorSubscription.unsubscribe();
    this.addressSubscription.unsubscribe();    

        
    this.inputChanged();
    
  }

  ngOnInit(): void {
    if(this.cookieService.check(this.cookieName)) {
      this.address = this.cookieService.get(this.cookieName);
    } else {
      this.address = "נתניה";
    }

    this.latSubscription = this.localTime.latetude.subscribe((lat: number) =>{
      this.isError = false;
      
      this.latitudeEvent.emit(lat);
      this.latitude = lat;
    });

    this.isErrorSubscription = this.localTime.isError.subscribe((isError: boolean) => {
      this.isError = isError;      
    });

    this.addressSubscription = this.localTime.addres.subscribe((address: string) => {});

    this.inputEnter();
  }

  inputChanged() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  inputEnter() {    
    this.cookieService.delete(this.cookieName);
    this.cookieService.set(this.cookieName, this.address, 36, "/");
    this.inputChanged();
    this.interval = setInterval( () => {
      
      this.localTime.getLatitude(this.address);
           
    }, 1000) as any;
  }

}
