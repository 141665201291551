import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { YMDs } from 'src/app/classes/hebrew-calculator';
import { isDST } from 'src/app/utils/hebrew-units-converters';

export interface IPlanetCharacteristics {
  description: string;
  planets: number[];
  planetsNames?: string;
  planetsClass?: string;
}

export interface IWeekDay {
  weekDay: number;
  planets: IPlanetCharacteristics[];
  description: string;
  wDayName?: string;
}

@Component({
  selector: 'app-week-day',
  templateUrl: './week-day.component.html',
  styleUrls: ['./week-day.component.css']
})
export class WeekDayComponent implements OnInit, OnChanges{
  @Input() hebrewDate!: YMDs;
  @Input() weekDay: IWeekDay = {
    weekDay: 0,
    planets:[
      {
        description: "",
        planets: [ 4 ]
      },
   ],
    description: "",  
    wDayName: ""  
  };
  constructor() { }
  ngOnChanges(_changes: SimpleChanges): void {
    if (this.hebrewDate !== undefined && this.weekDay !== undefined) {
      this.setRulingClass();
    }    
  }

  ngOnInit(): void {
    // this.setRulingClass();
  }
  
  private setRulingClass() {
    let weekDay = this.hebrewDate.wDays
    if (weekDay == 0) {
      weekDay = 7;
    }
    weekDay--;

    const isWeekDay = this.hebrewDate.wDays === this.weekDay.weekDay;
    const dstStar = isDST(new Date())? 1 : 0;
    const star = (weekDay * 24 + this.hebrewDate.dayRemain.timeUnit.hours + 5 + dstStar) % 7;

    for (let i = 0; i < this.weekDay.planets.length; i++) {
      const planets = this.weekDay.planets[i];
      for (let j = 0; j < planets.planets.length; j++) {
        const isWeekDay = this.hebrewDate.wDays === this.weekDay.weekDay;
        planets.planetsClass = (planets.planets.includes(star) && isWeekDay)  ? "rulingHour" : "notRulingHour";        
      }      
    }
  }

}
