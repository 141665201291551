import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { YMDs } from '../classes/hebrew-calculator';
import { jerusalemLatitude } from '../utils/hebrew-location';

@Injectable({
  providedIn: 'root'
})
export class HebrewCalenderService {
  private _hebrewYMDs = new YMDs();
  get hebrewYMDs() {
    return this._hebrewYMDs;
  }
  hebrewDateTime = new Subject<YMDs>();

  latitude = jerusalemLatitude;

  constructor() { }

  setHebrewDate(ymds: YMDs) {
    this._hebrewYMDs = ymds;
    this.hebrewDateTime.next(ymds);
  }

}
