import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaTagsService } from '../services/meta-tags.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(private titleService: Title,
    private metaService: MetaTagsService) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    const description = "זמן ירושלים וזמן המקומי המיוחד לכל מקום ומקום על פי קו הרוחב, ומתיחס לענין התקופות שליטת הכוכבי השעה וההלכות הקשורים בזמנים אלה.";
    this.metaService.updateDescription(description);
    this.titleService.setTitle("מזלות השולטים");    
  }
}

