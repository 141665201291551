<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <div class="text-div">
            <p dir="rtl" class="text-center">כוכב השולט בתקופה יהיה אותו כוכב בכל העולם, לכן החיזוי התקופתי הוא שווה לכל העולם, היות שהתקופה תתרחש בכל מקום בהגיע זמן התקופה ובכל מקום יהיה זה אותו זמן לכן ישלוט אותו כוכב.</p>
            <p dir="rtl" class="text-center">מה שאין כן בזמן המולד, החיזוי יהיה שונה ממקום למקום, היות שזמן המולד הוא לפי זמן ירושלים, וזמן זה יהיה שונה בכל מקום לפי ריחוק קו הרוחב מירושלים לכן בכל מקום הכוכב השולט בזמן המולד יהיה שונה לפי מיקום המקום בקו הרוחב.</p>
        </div>
        <h5 dir="rtl" class="text-center">התקופה הנוכחית</h5>
    </div>
</div>

<app-season-forecast [season]="selectedSeason"
[latitude]="latitude"></app-season-forecast>

<app-season-forecast
*ngFor="let s of seasions"
[season]="s"
[latitude]="latitude"></app-season-forecast>
