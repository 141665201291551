import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-season-latitude',
  templateUrl: './season-latitude.component.html',
  styleUrls: ['./season-latitude.component.css']
})
export class SeasonLatitudeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
