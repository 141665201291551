import { DaysPast, getDateFromDaysPast, intDivideRemain, roundAndRemain, TimeAccessor, YMDs } from "../classes/hebrew-calculator";

const jerusalemLatitude = 35.202556;
const startNum = 1607269151386;
const startYMDs = new YMDs(5781, 3, 21);

const getLocalTime = (latitude: number): Date => {
    const diff = latitude - jerusalemLatitude;
    const jerusalemTime = getJerusalemTime();
    var fromUtc = new Date(jerusalemTime.getTime() + (diff * 4 * 60 * 1000));

    return fromUtc;
  }

  const unixTimeToDays = (n: number): number => {
    return n / 1000 / 3600 / 24;
  }

  const getJerusalemTime = (currentDate = new Date()):Date => {
    
    const intDev = intDivideRemain(jerusalemLatitude, 15);
    let jerusaem = new Date(currentDate.getTime() + 
    (currentDate.getTimezoneOffset() * 60000) + 
    (intDev.remain * 4  * 60 * 1000) + (intDev.divided * 3600 * 1000));

    return jerusaem;
  }

  const getHebrewDate = (dateNow: Date, startDate: Date): YMDs => {
    let days = unixTimeToDays(dateNow.getTime() - startDate.getTime());// / 1000 / 60 / 60 / 24);

    const daysPastStart = new DaysPast(startYMDs);
    const newTime = daysPastStart.daysPastTimeAccessor.plus(new TimeAccessor(days), false);
    const currentHebrewDate = getDateFromDaysPast(newTime);
    return currentHebrewDate;
  }

  const getLocalHebrewDate = (latitude: number): YMDs => {
    const startDate = getJerusalemTime(new Date(startNum));
    const dateNow = getLocalTime(latitude);

    const currentHebrewDate = getHebrewDate(dateNow, startDate);
    
    return currentHebrewDate;
  
  }

  const getJerusalemHebrewDate = ():YMDs => {
    const startDate = getJerusalemTime(new Date(startNum));
    const dateNow = getJerusalemTime(new Date());

    const currentHebrewDate = getHebrewDate(dateNow, startDate);
    
    return currentHebrewDate;
  }

  const gethoursTimeAccesor = (h: number): TimeAccessor => {
    let hours = roundAndRemain(h);
    let halakim = roundAndRemain(hours.remain * 1080);
    let regaim = roundAndRemain(halakim.remain * 76);

    return new TimeAccessor(0, hours.round, halakim.round, regaim.round);  
  }

  export interface IHourDiff {
    ta: TimeAccessor;
    isMinus: boolean;
  }

  const getHoursDiffFromJerusalem = (latitude: number): IHourDiff => {
    let hoursDiff = (latitude - jerusalemLatitude) / 15;
    let isMinus = hoursDiff < 0;
    hoursDiff = Math.abs(hoursDiff);
    let ta = gethoursTimeAccesor(hoursDiff);

    return {
      ta,
      isMinus
    }
  }


  const converJerusalemToLocal = (jerusalem: TimeAccessor, latitude: number): TimeAccessor => {
    const ta = getHoursDiffFromJerusalem(latitude!);
    if (ta.isMinus) {
      return jerusalem.minus(ta.ta);
    }

    return jerusalem.plus(ta.ta, false);
  }

  const getTimeZoneLatitudeOffset = (currentDate = new Date()): number => {
    return currentDate.getTimezoneOffset() / 4;
  }

  const timeZoneToLatitudeCompensation = (latitude: number, ta: TimeAccessor): TimeAccessor => {

    const diff = -(latitude + getTimeZoneLatitudeOffset())
    let hours = diff / 15;
  
    const isMinus = hours < 0;
    hours = Math.abs(hours)
    const compensator = gethoursTimeAccesor(hours);

    if (isMinus) {
      return ta.minus(compensator);
    }

    return ta.plus(compensator, false);
  }

export {
    jerusalemLatitude,
    startNum,
    startYMDs,
    getLocalTime,
    getJerusalemTime,
    getHebrewDate,
    getLocalHebrewDate,
    getJerusalemHebrewDate,
    unixTimeToDays,
    gethoursTimeAccesor,
    getHoursDiffFromJerusalem,
    converJerusalemToLocal,
    getTimeZoneLatitudeOffset,
    timeZoneToLatitudeCompensation
  }  
