import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DaysPast, getDateFromDaysPast, TimeAccessor, YMDs } from 'src/app/classes/hebrew-calculator';
import { getLocalTime, timeZoneToLatitudeCompensation, unixTimeToDays } from 'src/app/utils/hebrew-location';
import { getFormatedHebrewDateTime, IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-heb-units',
  templateUrl: './heb-units.component.html',
  styleUrls: ['./heb-units.component.css']
})
export class HebUnitsComponent implements OnInit, OnChanges {
  @Input() hebrewDate!: IFormatedHebrewDateTime;
  @Input() latitude: number | undefined;
  @Input() latitudeTotimezone: number | undefined;
  isLatitude = false;

  loazyDate: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  zoneDate: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hebrewDate === undefined) {
      return;
    }
    const hebrewDate = changes['hebrewDate'].currentValue as IFormatedHebrewDateTime;
    const daysPast = new DaysPast(hebrewDate.hebrewDate);
    const ta = daysPast.daysPastTimeAccessor.minus(new TimeAccessor(0, 6));
    const ymds = getDateFromDaysPast(ta);
    this.loazyDate = getFormatedHebrewDateTime(ymds);

    if (this.latitude !== undefined) {
      this.isLatitude = true;

      const local = getLocalTime(this.latitude);
      const current = new Date();
      let daysDiff = unixTimeToDays(current.getTime() - local.getTime());
      let isMinus = daysDiff < 0;
      daysDiff = Math.abs(daysDiff);
      let diffTa = new TimeAccessor(daysDiff).organizer(false);
      let zoneTa: TimeAccessor;
      if (isMinus) {
        zoneTa = ta.minus(diffTa);
      } else {
        zoneTa = ta.plus(diffTa, false);
      }

      this.zoneDate = getFormatedHebrewDateTime(getDateFromDaysPast(zoneTa))
    } 
    else if (this.latitudeTotimezone !== undefined) {
      this.isLatitude = true;

      const compensatedLatitudeToTimeZone = timeZoneToLatitudeCompensation(this.latitudeTotimezone, ta);
      this.zoneDate = getFormatedHebrewDateTime(getDateFromDaysPast(compensatedLatitudeToTimeZone));
    }
  }

  ngOnInit(): void {
  }

}
