import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { YMDs } from 'src/app/classes/hebrew-calculator';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { isDST } from 'src/app/utils/hebrew-units-converters';
import { IPlanet } from './planet/planet.component';

@Component({
  selector: 'app-planets-hours',
  templateUrl: './planets-hours.component.html',
  styleUrls: ['./planets-hours.component.css']
})
export class PlanetsHoursComponent implements OnInit, OnDestroy {
  rulingPlanet!: IPlanet;
  planets: IPlanet[] = [
    {
      name: "שבתאי",
      humanTrait: "הנולד בשעת שליטת שבתאי - יהיה אדם שמחשבותיו בטלות, ויש אומרים שיהיה אדם שכל מחשבה שחושבים עליו תתבטל (שבת קנו).",
      rulingOn: "ממונה העניות ועל המסכנות ועל הדוויה ועל החריבה ועל החולי והמכה הטמונה בגוף ועל המיתה (ברייתא דשמואל הקטן)."
    },
    {
      name: "צדק",
      humanTrait: "הנולד בשעת שליטת צדק - יהיה בעל צדקה (שבת קנו).",
      rulingOn: "ממונה על החיים ועל השלום ועל הטובה ועל השלוה ועל ההשקט ועל התורה ועל השמחה ועל העליזה ועל העושר ועל הכבוד ועל השררה (ברייתא דשמואל הקטן)."
    }  
    ,
    {
      name: "מאדים",
      humanTrait: "הנולד בשעת שליטת מאדים - יהיה שופך דמים או אומן המקיז דם או לסטים ההורג נפשות או שוחט או מוהל וכו' (שבת קנו).",
      rulingOn: "מאדים ממונה על הדם ועל החרב ועל הרשע והקטטה ועל המריבות ועל החבורות ועל הפצעים ועל המלחמות ועל השנאה הקנאה והאיבה והתחרות ועל המכות ועל הברזל ועל האש ועל המים ועל המפולת (ברייתא דשמואל הקטן)."
    }  
    ,
    {
      name: "חמה",
      humanTrait: "הנולד בשעת שליטת חמה - יהיה אדם בעל זיו פנים כשמש המאירה. יהיה אוכל משלו ושותה משלו, כשמש שאינה מסיגה את גבול הלבנה להאיר בלילה, וסודותיו יהיו גלוים לכל כשמש שהיא גלויה לכל, ואם ינסה ליגנוב לא יצליח (שבת קנו).",
      rulingOn: "ממונה על המאור ועל החושך להבדיל בין היום ובין הלילה למנות בו ימים וחודשים ולעשות בו כל מלאכה. ולפעול בו כל פעולה ולהלוך בו כל הילוך וליגלות בו כל גלות מעיר לעיר וממדינה למדינה (ברייתא דשמואל הקטן)."
    }  
    ,
    {
      name: "נגה",
      humanTrait: "הנולד בשעת שליטת נגה - יהיה אדם עשיר ונואף יהיה שבמזל זה תלויה אש התאוה (יצר התשמיש) הבוערת כתנור (שבת קנו).",
      rulingOn: "ממונה על החן והחסד והאהבה ועל ולדי האדם ועל ולדי בהמה ועל פירות הארץ ועל פירות האילן (ברייתא דשמואל הקטן)."
    }  
    ,
    {
      name: "כוכב",
      humanTrait: "הנולד בשעת שליטת כוכב - יהיה אדם בעל זיו פנים וחכם משום שכוכב זה הוא (סופר) השמש, שתמיד מצוי אצלה לכתוב את דרך הילוכה ותקופותיה (שבת קנו).",
      rulingOn: "ממונה על החכמה ועל ההשכל ועל הבינה ועל הדעת ועל המדע לפתח כל כל פיתוח ולחשוב כל מחשבות בכל מלאכת אומנות ועל הכתב וכל הלשון (ברייתא דשמואל הקטן)."
    }  
    ,
    {
      name: "לבנה",
      humanTrait: "הנולד בשעת שליטת הלבנה - יהיה אדם סובל חלאים, כלבנה שהיא מתמעטת והולכת, הוא יבנה ויהרוס כלבנה הגדלה ומתמעטת, הוא יאכל דברים שאינם שלו וישתה דברים שאינם שלו, כלבנה המסיגה את גבול החמה ונראית לפעמים ביום, וסודותיו יהיו מכוסים, כלבנה שאינה מאירה, ואם יגנוב יצליח (שבת קנו).",
      rulingOn: "ממונה על מפתחי שמים וארץ (ברייתא דשמואל הקטן)."
    }  
  ];

  lastHour = -1;

  constructor(private datetimeNotify: DatetimeNotifyService,
    private titleService: Title,
    private metaService: MetaTagsService) { }
  ngOnDestroy(): void {
    
  }

  ngOnInit(): void {
    this.metaService.updateDescription("זמן שליטת כוכבי השעה בכל מקום ותכונות האדם הנולד בהם, ובמה הם מושלים.");
    this.titleService.setTitle("כוכבי השעה");
    this.rulingPlanet = this.planets[0];
    this.datetimeNotify.hebrewDateTime.subscribe((hebrewDate: YMDs) => {
      // Update once a day
      if (this.lastHour !== hebrewDate.dayRemain.timeUnit.hours) {
        this.lastHour = hebrewDate.dayRemain.timeUnit.hours
      } else {
        return ;
      }

      let weekDay = hebrewDate.wDays
      if (weekDay == 0) {
        weekDay = 7;
      }
      weekDay--;
      const dstStar = isDST(new Date())? 1 : 0; 
      const star = (weekDay * 24 + hebrewDate.dayRemain.timeUnit.hours + 5 + dstStar) % 7;

      this.rulingPlanet = this.planets[star];
    });
  }

}
