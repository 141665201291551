
const fixedTo15 = (n:number, d:number = 15): number =>  {
    return +n.toFixed(d);
}

interface IDevidResult{
    remain: number,
    divided: number
}

const intDivideRemain = (num:number, div:number):IDevidResult => {
    div = Math.round(div);
    const remain: number = fixedTo15(num % div);
    const divided = Math.round((num - remain)  / div);

    return {
        remain,
        divided
    }
}


interface IRoundRemain{
    round: number,
    remain: number
}

const roundAndRemain = (n:number): IRoundRemain => {
    const round = Math.floor(n);
    let remain = 0;

    if (!isNaN( round)) {
        
        remain = fixedTo15(n - round);
    }

    return {
        round,
        remain
    }

}

const clone = (obj: any): any => {
    let copy = (JSON.parse(JSON.stringify(obj)));

    return copy;
}

class   YearTypesAndCycles {
    isleapYear: boolean | undefined;    
    cycles: number = 0;    
    leapYears: number = 0;    
    normalYears: number = 0;    
    years: number = 0;  
    
    set() {
        this.cycles = this.leapYears = this.normalYears = this.years = 0;
    }

    constructor(y: number = 0) {
        if (y > 0) {
          this.setYear(y)  ;
        }
    }

    currentCycleYearsMode() {
        var y = this.years;

        y--;
        var divided = intDivideRemain(y, 19);
        this.cycles = divided.divided;
        switch (divided.remain) {
// @ts-ignore
            case 18: this.normalYears++;
// @ts-ignore
            case 17: this.leapYears++;
// @ts-ignore
            case 16: this.normalYears++;
// @ts-ignore
            case 15: this.normalYears++;
// @ts-ignore
            case 14: this.leapYears++;
// @ts-ignore
            case 13: this.normalYears++;
// @ts-ignore
            case 12: this.normalYears++;
// @ts-ignore
            case 11: this.leapYears++;
// @ts-ignore
            case 10: this.normalYears++;
// @ts-ignore
            case 9:  this.normalYears++;
// @ts-ignore
            case 8:  this.leapYears++;
// @ts-ignore
            case 7:  this.normalYears++;
// @ts-ignore
            case 6:  this.leapYears++;
// @ts-ignore
            case 5:  this.normalYears++;
// @ts-ignore
            case 4:  this.normalYears++;
// @ts-ignore
            case 3:  this.leapYears++;
// @ts-ignore
            case 2:  this.normalYears++;
// @ts-ignore
            case 1:  this.normalYears++;
            case 0:
                break;
        }
    }

    setLeapMode() {
        switch (this.years % 19) {
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
            case 9:
            case 10:
            case 12:
            case 13:
            case 15:
            case 16:
            case 18: this.isleapYear = false;
                break;
            case 3:
            case 6:
            case 8:
            case 11:
            case 14:
            case 17:
            case 0: this.isleapYear = true;
                break;
        }

    }

    setYear(y: number): YearTypesAndCycles {
        this.set();
        if (y !== undefined) {
            this.years = Math.round(y);
            this.currentCycleYearsMode();
            this.setLeapMode();
        }

        return this;

    }

 }

 interface ITimeUnit {
     days: number;
     hours: number;
     halakim: number;
     regaim: number;
     regaimPercent: number;
 }

 class TimeAccessor {
    timeUnit: ITimeUnit = {
        days: 0,
        hours: 0,
        halakim: 0,
        regaim: 0,
        regaimPercent: 0
    }

    constructor(days: number = 0, 
        hours: number = 0, 
        halakim: number = 0, 
        regaim: number = 0, 
        regaimPercent: number = 0) {

        this.setUnits(days, hours, halakim, regaim, regaimPercent);
    }

    setUnits(days: number = 0, 
        hours: number = 0, 
        halakim: number = 0, 
        regaim: number = 0, 
        regaimPercent: number = 0) {
        var divRes = roundAndRemain(days);
        if (divRes.remain !== 0) {
            days = divRes.round;
            divRes = roundAndRemain(divRes.remain * 24);
            hours = divRes.round;
            divRes = roundAndRemain(divRes.remain * 1080);
            halakim = divRes.round;
            divRes = roundAndRemain(divRes.remain * 76);
            regaim = divRes.round;
            divRes = roundAndRemain(divRes.remain * 100);
            regaimPercent = divRes.round;
        }

        this.timeUnit = {
            days,
            hours,
            halakim,
            regaim,
            regaimPercent
        }
    }

    organizer(bRemain: boolean = true): TimeAccessor {
        let tregaimPercent = this.timeUnit.regaimPercent;
        let tregaim = this.timeUnit.regaim;
        let thalakim = this.timeUnit.halakim;
        let thours = this.timeUnit.hours;
        let tdays = this.timeUnit.days;

        tregaimPercent += tregaim * 100;
        tregaim = 0;
        tregaimPercent += thalakim * 76 * 100;
        thalakim = 0;
        tregaimPercent += thours * 1080 * 76 * 100;
        thours = 0;
        tregaimPercent += tdays * 24 * 1080 * 76 * 100;
        tdays = 0;

        let rem = intDivideRemain(tregaimPercent, 100);
        tregaimPercent = rem.remain;
        rem = intDivideRemain(rem.divided, 76);
        tregaim = rem.remain;
        rem = intDivideRemain(rem.divided, 1080);
        thalakim = rem.remain;
        rem = intDivideRemain(rem.divided, 24);
        thours = rem.remain;
        tdays = rem.divided;
        if (bRemain) {
            tdays %= 7;
        }

        this.timeUnit.regaimPercent = tregaimPercent;
        this.timeUnit.regaim = tregaim;
        this.timeUnit.halakim = thalakim;
        this.timeUnit.hours = thours;
        this.timeUnit.days = tdays;

        return this;
    }

    plus(timeAccessor: TimeAccessor, bRemain: boolean = true): TimeAccessor {
        var tu = timeAccessor.timeUnit;
        var ta = new TimeAccessor(tu.days + this.timeUnit.days,
            tu.hours + this.timeUnit.hours, tu.halakim + this.timeUnit.halakim,
            tu.regaim + this.timeUnit.regaim, tu.regaimPercent + this.timeUnit.regaimPercent);

        ta.organizer(bRemain);

        return ta;
    }

    
    public get floatTimeUnit() : number {
        var floatUnit = this.timeUnit.days;
        floatUnit += this.timeUnit.hours / 24;
        floatUnit += this.timeUnit.halakim / (24 * 1080);
        floatUnit += this.timeUnit.regaim / (24 * 1080 * 76);
        floatUnit += this.timeUnit.regaimPercent / (24 * 1080 * 76 * 100);
        return floatUnit;
    }
    

    minus(timeAccessor: TimeAccessor): TimeAccessor {
        this.organizer(false);
        timeAccessor.organizer(false);
        if (this.floatTimeUnit < timeAccessor.floatTimeUnit) {
            return new TimeAccessor();
        }

        var tu = timeAccessor.timeUnit;
        var thisTu = clone(this.timeUnit);
        var tregaimPersent = thisTu.regaimPercent - tu.regaimPercent;
        if (tregaimPersent < 0) {
            tregaimPersent += 100;
            if (thisTu.regaim > 0) {
                thisTu.regaim--;
            }
            else if (thisTu.halakim > 0) {
                thisTu.halakim--;
                thisTu.regaim = 75;
            }
            else if (thisTu.hours > 0) {
                thisTu.hours--;
                thisTu.halakim = 1079;
                thisTu.regaim = 75;
            }
            else {
                thisTu.days--;
                thisTu.hours = 23;
                thisTu.halakim = 1079;
                thisTu.regaim = 75;
            }
        }

        var tregaim = thisTu.regaim - tu.regaim;
        if (tregaim < 0) {
            tregaim += 76;
            if (thisTu.halakim > 0) {
                thisTu.halakim--;
            }
            else if (thisTu.hours > 0) {
                thisTu.hours--;
                thisTu.halakim = 1079;
            }
            else {
                thisTu.days--;
                thisTu.hours = 23;
                thisTu.halakim = 1079;
            }
        }

        var thalakim = thisTu.halakim - tu.halakim;
        if (thalakim < 0) {
            thalakim += 1080;
            if (thisTu.hours > 0) {
                thisTu.hours--;
            }
            else {
                thisTu.days--;
                thisTu.hours = 23;
            }
        }

        var thours = thisTu.hours - tu.hours;
        if (thours < 0) {
            thours += 24;
            thisTu.days--;
        }
        var tdays = thisTu.days - tu.days;

        return new TimeAccessor(Math.round(tdays), Math.round(thours), Math.round(thalakim), Math.round(tregaim), Math.round(tregaimPersent));
    }

    plusAssign(timeAccessor: TimeAccessor, bRemain: boolean = true):TimeAccessor {
        var tu = timeAccessor.timeUnit;

        this.timeUnit.days += tu.days;
        this.timeUnit.hours += tu.hours;
        this.timeUnit.halakim += tu.halakim;
        this.timeUnit.regaim += tu.regaim;
        this.timeUnit.regaimPercent += tu.regaimPercent;

        this.organizer(bRemain);

        return this;   
    }

    multiply(mul: number, bRemain: boolean = true): TimeAccessor {
        var ta = new TimeAccessor(this.timeUnit.days * mul,
            this.timeUnit.hours * mul,
            this.timeUnit.halakim * mul,
            this.timeUnit.regaim * mul,
            this.timeUnit.regaimPercent * mul);


        ta.organizer(bRemain);

        return ta;

    }

 }

class BaseRemain {

    readonly cycleRemaind = new TimeAccessor(2, 16, 595);
    readonly leapYearRemaind = new TimeAccessor(5, 21, 589);
    readonly normalYearRemaind = new TimeAccessor(4, 8, 876);
    readonly monthRemaind = new TimeAccessor(1, 12, 793);
    readonly molladTohho = new TimeAccessor(2, 5, 204);
} 

class BaseSum {
    readonly cycleSum = new TimeAccessor(6939, 16, 595);
    readonly monthSum = new TimeAccessor(29, 12, 793);
    readonly moladTohho = new TimeAccessor(0, 5, 204);

}

enum DhiyaReason {
    DhiyaReasonNone =       0,
    DhiyaReasonADORosh =    1,
    DhiyaReason_18 =        2,
    DhiyaReason_3_9_204 =   4,
    DhiyaReason_2_15_589 =  8

}

enum YearKind {
    ShanaError = -1,
    ShanaHasserra = 0,
    ShanaKessidra = 1,
    ShanaMelleha =  2
}

enum RoshHodeshKind {
    RoshHodeshErr =     -10,
    RoshHodesh2Days =    -1,
    RoshHodesh1Day =     0,
    RoshHaShana =        1,

}

class Mollad extends YearTypesAndCycles {
    private br = new BaseRemain();
    month: number = 0;
    isDateCorrect: boolean | undefined;
    timeAccessor!: TimeAccessor;
    private _dhiyaReason!: DhiyaReason;
    baharadToMollad!: TimeAccessor;
    baharadToRoshHaShana!: TimeAccessor;

    constructor(year: number, month: number = 1) {
        super(year);

        this.setDate(year, month);
    }

    public get timeUnit(): ITimeUnit {
        return this.timeAccessor.timeUnit;
    }

    public get floatTimeUnit(): number   {
        return this.timeAccessor.floatTimeUnit;
    }

    public get roshHodeshKind(): RoshHodeshKind {
        if (this.isDateCorrect) {
            var yearKind = this.yearKind;
            switch (this.month) {
                case 13: return RoshHodeshKind.RoshHodesh2Days;
                case 12: if (this.isleapYear)
                    return RoshHodeshKind.RoshHodesh1Day;
                else
                    return RoshHodeshKind.RoshHodesh2Days;
                case 11: if (this.isleapYear)
                    return RoshHodeshKind.RoshHodesh2Days;
                else
                    return RoshHodeshKind.RoshHodesh1Day;
                case 10: if (this.isleapYear)
                    return RoshHodeshKind.RoshHodesh1Day;
                else
                    return RoshHodeshKind.RoshHodesh2Days;
                case 9: if (this.isleapYear)
                    return RoshHodeshKind.RoshHodesh2Days;
                else
                    return RoshHodeshKind.RoshHodesh1Day;
                case 8: if (this.isleapYear || this.years === 1)
                    return RoshHodeshKind.RoshHodesh1Day;
                else
                    return RoshHodeshKind.RoshHodesh2Days;
                case 7: if (this.isleapYear)
                    return RoshHodeshKind.RoshHodesh2Days;
                else
                    return RoshHodeshKind.RoshHodesh1Day;
                case 6: return RoshHodeshKind.RoshHodesh2Days;
                case 5: return RoshHodeshKind.RoshHodesh1Day;
                case 4: if (yearKind === YearKind.ShanaHasserra || this.years === 1)
                    return RoshHodeshKind.RoshHodesh1Day;
                else
                    return RoshHodeshKind.RoshHodesh2Days;
                case 3: if (yearKind === YearKind.ShanaMelleha)
                    return RoshHodeshKind.RoshHodesh2Days;
                else
                    return RoshHodeshKind.RoshHodesh1Day;
                case 2: return RoshHodeshKind.RoshHodesh2Days;
                case 1: return RoshHodeshKind.RoshHaShana;

            }
        }

        return RoshHodeshKind.RoshHodeshErr;

    }

    public get daysInMonth():number {
        if (this.isDateCorrect) {
            if ((this.isleapYear && this.month === 13) || (!this.isleapYear && this.month === 12)) {
                return 29;
             }
             else {
                var info = new Mollad(this.years, this.month + 1);
                if (info.roshHodeshKind === RoshHodeshKind.RoshHodesh1Day) {
                    return 29;
                }

                return 30;
            }
        }
        return -1;

    }

    public get baharadToKviha(): number {
        var yomRoshHaShana = this.yomRoshHaShana;
        if (yomRoshHaShana === 0) {
            yomRoshHaShana = 7;
        }

        var dayMoladRoshHaShana = (new Mollad(this.years)).timeUnit.days;
        if (dayMoladRoshHaShana === 0) {
            dayMoladRoshHaShana = 7;
        }
        if (yomRoshHaShana < dayMoladRoshHaShana) {
            yomRoshHaShana += 7;
        }

        var addDays = yomRoshHaShana - dayMoladRoshHaShana;

        var baharadToKviha = this.baharadToRoshHaShana.timeUnit.days + addDays;
        baharadToKviha += this.daysFromRHShanna;

        return baharadToKviha;

    }

    public get yomKviha(): number {
        if (!this.isDateCorrect) {
            return -1;
        }

        var yomKviha = this.yomRoshHaShana + this.daysFromRHShanna;

        return yomKviha % 7;

    }

    public get daysFromRHShanna(): number {
        if (!this.isDateCorrect) {
            return -1;
        }
        var daysPast = 0;
        var yearKind = this.yearKind;
        switch (this.month) {
// @ts-ignore            
            case 13: daysPast += 30;
// @ts-ignore            
            case 12: if (this.isleapYear)
                daysPast += 29;
            else
                daysPast += 30;
// @ts-ignore            
            case 11: if (this.isleapYear)
                daysPast += 30;
            else
                daysPast += 29;
// @ts-ignore            
            case 10: if (this.isleapYear)
                daysPast += 29;
            else
                daysPast += 30;
// @ts-ignore            
            case 9: if (this.isleapYear)
                daysPast += 30;
            else
                daysPast += 29;
// @ts-ignore            
            case 8: if (this.isleapYear)
                daysPast += 29;
            else
                daysPast += 30;
// @ts-ignore            
            case 7: if (this.isleapYear || this.years === 1)
                daysPast += 30;
            else
                daysPast += 29;
// @ts-ignore            
            case 6: daysPast += 30;
// @ts-ignore            
            case 5: daysPast += 29;
// @ts-ignore            
            case 4: if (yearKind === YearKind.ShanaHasserra || this.years === 1)
                daysPast += 29;
            else
                daysPast += 30;
// @ts-ignore            
            case 3: if (yearKind === YearKind.ShanaMelleha)
                daysPast += 30;
            else
                daysPast += 29;
// @ts-ignore            
            case 2: daysPast += 30;
            case 1:;

        }

        return daysPast;
    }

    public get daysInYear(): number {
        if (this.isleapYear) {
            return +this.yearKind + 383;
        }

        return +this.yearKind + 353;

    }

    public get yearKind(): YearKind {
        if (this.years < 1) {
            return YearKind.ShanaError;
        }

        var current = new Mollad(this.years).yomRoshHaShana;
        var next = new Mollad(this.years + 1).yomRoshHaShana;

        if (current >= next) {
            next += 7;
        }

        var yearKind = next - current;
        yearKind -= this.isleapYear ? 5 : 3;

        return yearKind;
    }

    public get dhiyaReason(): DhiyaReason {
        if (this._dhiyaReason === undefined) {
            let _ = this.yomRoshHaShana;
        }
        return this._dhiyaReason;

    } 

    public get yomRoshHaShana(): number {
        this._dhiyaReason = DhiyaReason.DhiyaReasonNone
        if (this.years === 1) {
            return 2;
        }

        let tu = this.month === 1 ? this.timeAccessor.timeUnit : new Mollad(this.years).timeUnit;
        let last = new Mollad(this.years - 1);
        let day = tu.days;

        if (day === 1 || day === 4 || day === 6) {
            day++;
            this._dhiyaReason |= DhiyaReason.DhiyaReasonADORosh;
        }
        else if (tu.hours >= 18) {
            day++;
            this._dhiyaReason |= DhiyaReason.DhiyaReason_18;
        } else if ((!this.isleapYear && day === 3)
            && (tu.hours === 9 && tu.halakim >= 204 || tu.hours > 9)) {
            day++;
            this._dhiyaReason |= DhiyaReason.DhiyaReason_3_9_204;
        } else if ((last.isleapYear && day === 2)
            && (tu.hours === 15 && tu.halakim >= 589 || tu.hours > 15)) {
            day++;
            this._dhiyaReason |= DhiyaReason.DhiyaReason_2_15_589;
        }

        if (day === 1 || day === 4 || day === 6) {
            day++;
            this._dhiyaReason |= DhiyaReason.DhiyaReasonADORosh;
        }

        return day % 7;
    }

    public get baharadPlusMollad():TimeAccessor  {
        return this.baharadToMollad.plus(new TimeAccessor(1));
    }

    calculator() {
        this.timeAccessor = this.br.monthRemaind.multiply(this.month - 1);
        this.timeAccessor.plusAssign(this.br.normalYearRemaind.multiply(this.normalYears))
        .plusAssign(this.br.leapYearRemaind.multiply(this.leapYears))
        .plusAssign(this.br.cycleRemaind.multiply(this.cycles))
        .plusAssign(this.br.molladTohho);

        var bs = new BaseSum();
        this.baharadToRoshHaShana = bs.cycleSum.multiply(this.cycles, false)
        .plusAssign(bs.monthSum.multiply(this.normalYears * 12, false), false)
        .plusAssign(bs.monthSum.multiply(this.leapYears * 13, false), false)
        .plusAssign(new TimeAccessor(0,5,204), false);
        //.plusAssign(br.molladTohho, false);

        this.baharadToMollad = this.baharadToRoshHaShana.plus(bs.monthSum.multiply(this.month - 1, false), false);
    }

    setDateCorrect() {
        if (this.isleapYear) {
            this.isDateCorrect = (this.month <= 13 && this.month > 0 && this.years > 0);
        } else {
            this.isDateCorrect = (this.month <= 12 && this.month > 0 && this.years > 0);
        }

    }

    setDate(y: number, m: number): Mollad {
        this.setYear(y);
        this.month = m;
        this.calculator();
        this.setDateCorrect();

        return this;
    }
}

class YMDs {
    years!: number;
    months!: number;
    mDays!: number;
    wDays = -1;
    dayRemain!: TimeAccessor;

    constructor(y: number = 1, m: number = 1, md: number = 1, ta: TimeAccessor = new TimeAccessor(), wd = -1) {
        this.years = y;
        this.months = m;
        this.mDays = md;
        this.dayRemain = ta;
        this.wDays = wd;
    }
}

const getNextMolad = (molad: Mollad): Mollad => {
    let month = molad.month;
    let years = molad.years;
    let lessThan = 12;
    if (molad.isleapYear) {
        lessThan++;
    }

    if (month < lessThan) {
        month++;

        return new Mollad(years, month);
    }

    years++;
    return new Mollad(years);
}

class DaysPast extends Mollad {
    mDays!: number;
    isDayInMonthCorrect!: boolean;
    daysPast!: number;
    dayPart = new TimeAccessor();
    wDays = -1;

    constructor(ymds: YMDs) {
        super(ymds.years, ymds.months);
        this.setDaysPast(ymds);
   }

    private setDaysPast(ymds: YMDs) {
        this.isDayInMonthCorrect = (ymds.mDays > 0 && ymds.mDays <= this.daysInMonth);
        this.daysPast = this.baharadToKviha + ymds.mDays - 1;
        this.mDays = ymds.mDays;
        this.wDays = (this.yomKviha + ymds.mDays - 1) % 7;
        this.dayPart = new TimeAccessor(0, ymds.dayRemain.timeUnit.hours, 
            ymds.dayRemain.timeUnit.halakim,
            ymds.dayRemain.timeUnit.regaim,
            ymds.dayRemain.timeUnit.regaimPercent);
    }

   setYmds(ymds: YMDs) {
        this.setDate(ymds.years, ymds.months);
        this.setDaysPast(ymds);
   }

   public get daysPastTimeAccessor(): TimeAccessor {
        return new TimeAccessor(this.daysPast, this.dayPart.timeUnit.hours, this.dayPart.timeUnit.halakim, this.dayPart.timeUnit.regaim, this.dayPart.timeUnit.regaimPercent);
   }

   public get YMDs(): YMDs {
       return new YMDs(this.years, this. month, this.mDays, new TimeAccessor(), this.wDays);
   }
}

const convertYMDsToTimeAccessor = (ymds:YMDs): TimeAccessor =>{
    const dp = new DaysPast(ymds);
    return dp.daysPastTimeAccessor;
}

const getDateFromDaysPast = (valDp: TimeAccessor): YMDs => {
    let dayPart = new TimeAccessor(0, valDp.timeUnit.hours, valDp.timeUnit.halakim, valDp.timeUnit.regaim, valDp.timeUnit.regaimPercent);
    let daysP = valDp.timeUnit.days;

    let cycleSum = (new BaseSum()).cycleSum.floatTimeUnit;
    let divided = intDivideRemain(daysP, cycleSum);
    let cycles = divided.divided;
    let years = cycles * 19 + 1;
    let ymds;
    let dayspast;
    let i;
    for (i = 0; i < 18; i++) {
        ymds = new YMDs(years + 1);
        dayspast = new DaysPast(ymds);
        if (dayspast.daysPast > daysP) {
            break;
        }
        years++;
    }

    var maxMonths = (new YearTypesAndCycles(years)).isleapYear ? 13 : 12;
    var month = 1;
    for (i = 2; i <= maxMonths; i++, month++) {
        ymds = new YMDs(years, month + 1);
        dayspast = new DaysPast(ymds);
        if (dayspast.daysPast > daysP) {
            break;
        }
    }

    let molad = new Mollad(years, month);
    let days = 1;
    let maxDaysInMonth = molad.daysInMonth;
    ymds = new YMDs(years, month, days);
    dayspast = new DaysPast(ymds);
    let wDay = dayspast.wDays;

    for (i = 2; i <= maxDaysInMonth; i++, days++) {
        ymds = new YMDs(years, month, days + 1);
        dayspast = new DaysPast(ymds);
        if (dayspast.daysPast > daysP) {
            break;
        }
        wDay = dayspast.wDays;
    }

    return new YMDs(years, month, days, dayPart, wDay);
}

const ymdsToNumber = (ymds: YMDs): number => {
    const n = ymds.years * 12 * 30 + ymds.months * 30 + ymds.mDays + ymds.dayRemain.floatTimeUnit;

    return n;

}

class Point3D {
    x!: number;
    y!: number;
    z!: number;

    constructor(x: number, y: number, z: number) {
        this.x = x;
        this.y = y;
        this.z = z;
    }
}

class Matrix {
    public static degToRad = Math.PI / 180.0;
    m: number[][] = [[1, 0, 0], [0, 1, 0], [0, 0, 1]];

    init() {
        this.m[0][0] = 1;
        this.m[0][1] = 0;
        this.m[0][2] = 0;
        this.m[1][0] = 0;
        this.m[1][1] = 1;
        this.m[1][2] = 0;
        this.m[2][0] = 0;
        this.m[2][1] = 0;
        this.m[2][2] = 1;
    }

    rotX(a: number) {
        var c = Math.cos(a * Matrix.degToRad);
        var s = Math.sin(a * Matrix.degToRad);
        this.m[0][0] = 1;
        this.m[0][1] = 0;
        this.m[0][2] = 0;
        this.m[1][0] = 0;
        this.m[1][1] = c;
        this.m[1][2] = s;
        this.m[2][0] = 0;
        this.m[2][1] = -s;
        this.m[2][2] = c;
    }

    rotY(a: number) {
        var c = Math.cos(a * Matrix.degToRad);
        var s = Math.sin(a * Matrix.degToRad);
        this.m[0][0] = c;
        this.m[0][1] = 0;
        this.m[0][2] = -s;
        this.m[1][0] = 0;
        this.m[1][1] = 1;
        this.m[1][2] = 0;
        this.m[2][0] = s;
        this.m[2][1] = 0;
        this.m[2][2] = c;
    }

    rotZ(a: number) {
        var c = Math.cos(a * Matrix.degToRad);
        var s = Math.sin(a * Matrix.degToRad);
        this.m[0][0] = c;
        this.m[0][1] = -s;
        this.m[0][2] = 0;
        this.m[1][0] = s;
        this.m[1][1] = c;
        this.m[1][2] = 0;
        this.m[2][0] = 0;
        this.m[2][1] = 0;
        this.m[2][2] = 1;
    }

    rotMulX(a: number) {
        let mat = new Matrix();

        mat.rotX(a);

        this.multiply(mat, this);       
    }

    rotMulY(a: number) {
        let mat = new Matrix();

        mat.rotY(a);

        this.multiply(mat, this);
    }

    rotMulZ(a: number) {
        let mat = new Matrix();

        mat.rotZ(a);

        this.multiply(mat, this);
    }

    multiply(ma1: Matrix, ma2: Matrix) {
        var m1 = clone(ma1);
        var m2 =  clone(ma2);
        this.m[0][0] = m1.m[0][0] * m2.m[0][0] + m1.m[0][1] * m2.m[1][0] + m1.m[0][2] * m2.m[2][0];
        this.m[0][1] = m1.m[0][0] * m2.m[0][1] + m1.m[0][1] * m2.m[1][1] + m1.m[0][2] * m2.m[2][1];
        this.m[0][2] = m1.m[0][0] * m2.m[0][2] + m1.m[0][1] * m2.m[1][2] + m1.m[0][2] * m2.m[2][2];
        this.m[1][0] = m1.m[1][0] * m2.m[0][0] + m1.m[1][1] * m2.m[1][0] + m1.m[1][2] * m2.m[2][0];
        this.m[1][1] = m1.m[1][0] * m2.m[0][1] + m1.m[1][1] * m2.m[1][1] + m1.m[1][2] * m2.m[2][1];
        this.m[1][2] = m1.m[1][0] * m2.m[0][2] + m1.m[1][1] * m2.m[1][2] + m1.m[1][2] * m2.m[2][2];
        this.m[2][0] = m1.m[2][0] * m2.m[0][0] + m1.m[2][1] * m2.m[1][0] + m1.m[2][2] * m2.m[2][0];
        this.m[2][1] = m1.m[2][0] * m2.m[0][1] + m1.m[2][1] * m2.m[1][1] + m1.m[2][2] * m2.m[2][1];
        this.m[2][2] = m1.m[2][0] * m2.m[0][2] + m1.m[2][1] * m2.m[1][2] + m1.m[2][2] * m2.m[2][2];
    }

    transform(p: Point3D) {
        var x = (p.x * this.m[0][0] + p.y * this.m[0][1] + p.z * this.m[0][2]);
        var y = (p.x * this.m[1][0] + p.y * this.m[1][1] + p.z * this.m[1][2]);
        var z = (p.x * this.m[2][0] + p.y * this.m[2][1] + p.z * this.m[2][2]);

        return new Point3D(x, y, z);
    }
}

export {
    fixedTo15,
    IDevidResult,
    intDivideRemain,
    IRoundRemain,
    roundAndRemain,
    YearTypesAndCycles,
    ITimeUnit,
    TimeAccessor,
    BaseRemain,
    BaseSum,
    DhiyaReason,
    YearKind,
    Mollad,
    getNextMolad,
    YMDs,
    DaysPast,
    getDateFromDaysPast,
    ymdsToNumber,
    convertYMDsToTimeAccessor,
    Matrix,
    Point3D
}
