import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { intDivideRemain, YMDs } from 'src/app/classes/hebrew-calculator';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { getRulingName, getWeekDayString } from 'src/app/utils/hebrew-units-converters';
import { IWeekDay } from './week-day/week-day.component';

@Component({
  selector: 'app-week-days',
  templateUrl: './week-days.component.html',
  styleUrls: ['./week-days.component.css']
})
export class WeekDaysComponent implements OnInit, OnDestroy {
  hebrewDate!: YMDs;
  lastDakot = -1;
  theweekDay: IWeekDay = {
    weekDay: 0,
    planets:[
      {
        description: "",
        planets: [ 4 ]
      },
   ],
    description: "",  
    wDayName: ""  
  };

  weekDays: IWeekDay[] = [
    {
      weekDay: 0,
      planets:[
        {
          description: "אם בעתו של נוגה נולד חצוף ובעל חימה ובעל מריבה יהיה, ומריב עם בני אדם וחוזר ומריב ואדם לא יוכל לכבוש אותו.",
          planets: [ 4 ]
        },
        {
          description: "ואם בעתו של צדק נולד יהיה הולך באימה וביראה ויהיה אהוב על הבריות.",
          planets: [ 1 ]
        },
        {
          description: "ואם בעתו של כוכב חמה נולד כבראשון כן הוא.",
          planets: [ 5 ]
        },
        {
          description: "ואם בעתו של מאדים נולד בן חורין יהיה, ובעל חימה ועז פנים, וסופו ספיקולטור, וביד חיה רעה יאבד.",
          planets: [ 2 ]
        },
        {
          description: "ואם בעתו של לבנה נולד אדם גבור ושופט יהיה.",
          planets: [ 6 ]
        }
     ],
      description: "הנולד בשבת תלוי בכוכב השולט"
    },
    {
      weekDay: 1,
      planets:[
        {
          description: "ואם בעתו של חמה נולד, יהיה יגע ומטורף כל ימי חייו, ומעשיו לא יהיו לו.",
          planets: [ 3 ]
        },
        {
          description: "ואם בעתו של כוכב חמה או צדק, אמת אוהב לדבר.",
          planets: [ 5, 1 ]
        },
        {
          description: "ואם בעתו של מאדים, מטרף ושופך דמים יהיה.",
          planets: [ 2 ]
        },
        {
          description: "ואם בעתו של נוגה נולד, זך יהיה, ויהיה מהלך בדרכים ומדינות.",
          planets: [ 4 ]
        },
        {
          description: "ואם בעתו של שבתי נולד, אדם גבור יהיה ובעל חימה, ולא יהיה עשיר.",
          planets: [ 0 ]
        }
     ],
      description: "הנולד באחד בשבת אדם גדול יהיה, ועצל במעשיו, מפני שנולד ביומה של חמה."
    },
    {
      weekDay: 2,
      planets:[
        {
          description: "ואם בעתו של לבנה נולד חכם ושופט יהיה.",
          planets: [ 6 ]
        },
        {
          description: "ואם בעתו של חמה נולד עני יהיה ומעשיו לא יהיו לו.",
          planets: [ 3 ]
        },
        {
          description: "ואם בעתו של כוכב חמה וצדק נולד, אדם צדיק יהיה.",
          planets: [ 1, 5 ]
        },
        {
          description: "ואם בעתו של מאדים נולד ספיקולטור יהיה.",
          planets: [ 2 ]
        },
        {
          description: "ואם בעתו של נוגה נולד אדם עשיר וחסיד יהיה, אבל סרוח בגופו יהיה.",
          planets: [ 4 ]
        }
     ],
      description: "הנולד בשני בשבת, שקול צרכים יהיה פעמים עשיר ופעמים עני, וחולק על בני אדם במקומו, ואינם ממתינים עמו לעולם מפני שנולד בימה של לבנה. וזה סימן במלאת הירח עד חצי החודש טוב הוא מפני שכל יום ויום מוסיף לפניו. כך הני בראש כוכבו של ירח, והני בסופו של כוכב הלבנה רע הוא מפני שהלבנה מחסרת, ולאחוריהם חוזרת ואינה הולכת לפניה."
    },
    {
      weekDay: 3,
      planets:[
        {
          description: "ואם בעתו של מאדים נולד לסטים יהיה, מפני שהוא כוכב של דם.",
          planets: [ 2 ]
        },
        {
          description: "ואם בעתו של נוגה נודל סרוח בגופו יהיה.",
          planets: [ 4 ]
        },
        {
          description: "ואם בעתו של כוכב חמה וצדק נולד אדם גדול יהיה ושופט אמת.",
          planets: [ 1, 5 ]
        },
        {
          description: "ואם בעתו של שבתי נולד אדם דיין והורג נפשות יהיה, ואחריתו יהיה מתאבל.",
          planets: [ 0 ]
        },
        {
          description: "ואם בעתו של חמה נולד תמים יהיה.",
          planets: [ 3 ]
        }
     ],
      description: "הנולד בשלישי בשבת, אדם עז פנים וגבה רוח מפני שנולד ביומו של מאדים."
    },
    {
      weekDay: 4,
      planets:[
        {
          description: "ואם בעתו של כוכב חמה וצדק נולד פיקח ועשיר וחכם יהיה.",
          planets: [ 5, 1 ]
        },
        {
          description: "ואם בעתו של של נוגה נולד גדול ועשיר יהיה וחולי מעיים יהיה.",
          planets: [ 4 ]
        },
        {
          description: "ואם בעתו של מאדים נולד פעמים טוב ופעמים רע.",
          planets: [ 2 ]
        },
        {
          description: "ואם בעתו של שבתי נולד חכם עשיר יהיה.",
          planets: [ 0 ]
        },
        {
          description: "ואם בעתו של חמה נולד אדם גדול יהיה ולא עשיר, ואור עיניו על בני אדם.",
          planets: [ 3 ]
        }
     ],
      description: "הנולד ברביעי בשבת אדם גדול וצדיק יהיה ושופט."
    },
    {
      weekDay: 5,
      planets:[
        {
          description: "ואם בעתו של צדק נולד דיין ועשיר ופקח יהיה.",
          planets: [ 1 ]
          
        },
        {
          description: "ואם בעתו של כוכב חמה ומאדים נולד פעמים טוב ופעמים רע.",
          planets: [ 5, 2 ]
        },
        {
          description: "ועם בעתו של נוגה נולד חסיד ועשיר יהיה.",
          planets: [ 4 ]
        },
        {
          description: "ועם בעתו של שבתי גבור ומהלך באמת.",
          planets: [ 0 ]
        },
        {
          description: "ואם בעתו של לבנה שופט באמת יהיה.",
          planets: [ 6 ]
        },
        {
          description: "ואם בעתו של חמה אדם דברן ואימתו ויראתו יהיה.",
          planets: [ 3 ]
        }
     ],
      description: "הנולד מחמישי בשבת, גדול ועשיר וחכם יהיה."
    },
    {
      weekDay: 6,
      planets:[
        {
          description: "ואם בעתו של נוגה נולד סרוח בגופו יהיה.",
          planets: [ 4 ]
        },
        {
          description: "ואם בעתו של מאדים עשיר וחסיד יהיה.",
          planets: [ 2 ]
        },
        {
          description: "ואם בעתו של צדק וכוכב חמה  פקח וחסיד ועשיר ואהוב יהיה.",
          planets: [ 1, 5 ]
        },
        {
          description: "ואם בעתו של שבתי נולד בעל מתאכזר יהיה.",
          planets: [ 0 ]
        },
        {
          description: "ואם בעתו של לבנה שופט יהיה ושם לו במקומו ושומעין דבריו.",
          planets: [ 6 ]
        }
     ],
      description: "הנולד בשישי בשבת חסיד ועשיר יהיה."
    }
  ];
  constructor(private datetimeNotify: DatetimeNotifyService,
    private titleService: Title,
    private metaService: MetaTagsService) { }
  ngOnDestroy(): void {
    //this.datetimeNotify.hebrewDateTime.unsubscribe();
  }

  ngOnInit(): void {
    this.metaService.updateDescription("תכונות המיוחדות לכוכב השעה בהתאם ליום שבו הוא שולט על פי בעל הרוקח הרב אלעזר מגרמייזא.");
    this.titleService.setTitle("הנולד בשבוע");
    this.initWeekDays();

    this.datetimeNotify.hebrewDateTime.subscribe((hebrewDate: YMDs) => {
      // One action in 2 minuts
      const dakot = intDivideRemain(hebrewDate.dayRemain.timeUnit.halakim, 36);
      if (dakot.divided !== this.lastDakot) {
        this.lastDakot = dakot.divided;
      } else {
        return;
      }

      this.hebrewDate = hebrewDate;
      this.theweekDay = this.weekDays[hebrewDate.wDays];
    });
  }

  private initWeekDays(){
    for (let i = 0; i < this.weekDays.length; i++) {
      let weekDay = this.weekDays[i];
      weekDay.wDayName = getWeekDayString(weekDay.weekDay);
      for (let j = 0; j < weekDay.planets.length; j++) {
        let planet = weekDay.planets[j];
        let planetName = "";
        for (let k = 0; k < planet.planets.length; k++) {
          const element = planet.planets[k];
          if (planetName !== "") {
            planetName += ", "
          }
          
          planetName += getRulingName(element);
        }
        planet.planetsNames = planetName;
        planet.planetsClass = "notRulingHour";
      }
    }
  }

}
