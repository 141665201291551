import { Component, OnDestroy, OnInit } from '@angular/core';
import { YMDs } from 'src/app/classes/hebrew-calculator';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { getRulingName, isDST } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-ruling-planets',
  templateUrl: './ruling-planets.component.html',
  styleUrls: ['./ruling-planets.component.css']
})
export class RulingPlanetsComponent implements OnInit, OnDestroy {
  hourPlanetName!: string;
  dayPlanet!: string;
  nightPlanet!: string;
  hourPlanetClass!: string;

  constructor(private datetimeNotify: DatetimeNotifyService) { }
  ngOnDestroy(): void {
    this.datetimeNotify.hebrewDateTime.unsubscribe();    
  }

  ngOnInit(): void {
    this.datetimeNotify.hebrewDateTime.subscribe((hebrewDate: YMDs) => {
      let weekDay = hebrewDate.wDays
      if (weekDay == 0) {
        weekDay = 7;
      }
      weekDay--;

      const dstStar = isDST(new Date())? 1 : 0;
      const star = (weekDay * 24 + hebrewDate.dayRemain.timeUnit.hours + 5 + dstStar) % 7;
      this.hourPlanetName = getRulingName(star);

      const night = (weekDay * 24 + 5) % 7;
      this.nightPlanet = getRulingName(night);

      const day = (weekDay * 24 + 17 ) % 7;
      this.dayPlanet = getRulingName(day);

      this.hourPlanetClass = hebrewDate.dayRemain.timeUnit.hours < 12 ? "night" : "day";
    });
  }

}
