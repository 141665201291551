import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { getDateFromDaysPast, YMDs, ymdsToNumber } from 'src/app/classes/hebrew-calculator';
import { ISeasons, YearSeasons } from 'src/app/classes/season';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { LocalTimeService } from 'src/app/services/local-time.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { getJerusalemHebrewDate, getLocalHebrewDate } from 'src/app/utils/hebrew-location';
import { getFormatedHebrewDateTime, IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-seasion-water',
  templateUrl: './seasion-water.component.html',
  styleUrls: ['./seasion-water.component.css']
})
export class SeasionWaterComponent implements OnInit, OnDestroy {
  address: string = '';
  isError = false;
  latitude: number | undefined;
  lastWDay = -1;
  isLatitudeCanged = false;

  seasions: ISeasons = YearSeasons.empty();;
  selectedSeason: IFormatedHebrewDateTime = getFormatedHebrewDateTime(getDateFromDaysPast(YearSeasons.firstSeasonNissan));
  selectesSeasonName!: string;


  private latSubscription!: Subscription;
  private isErrorSubscription!: Subscription;
  private addressSubscription!: Subscription;


  constructor(private localTime: LocalTimeService,
    private titleService: Title,
    private metaService: MetaTagsService,
    private headerTitle: HeaderTitleService) { }
  ngOnDestroy(): void {
  }

  private readonly seasonsKeys = ["tamuz","tishrei","tevet","nissan","tamuzNext","tishreiNext"];

  ngOnInit(): void {
    this.metaService.updateDescription("חישוב זמן אשר בו תחול התקופה הקרובה על פי מיקום, אשר אשר בזמן זה יש סכנה לשתיית מים.");
    this.titleService.setTitle("זמן איסור שתיית מים");
    this.headerTitle.setHeaderTitle("איסור שתיית בזמן התקופה");    


    this.latSubscription = this.localTime.latetude.subscribe((lat: number) =>{
      this.isError = false;
      if (this.latitude !== lat) {
        this.isLatitudeCanged =true;      
      }
        
      this.latitude = lat;

      this.setDate();
    });

    this.isErrorSubscription = this.localTime.isError.subscribe((isError: boolean) => {
      this.isError = isError;      
    });

    this.addressSubscription = this.localTime.addres.subscribe((address: string) => {
      this.address = address;
    });
}

  setDate() {
    const jerusalemTime = getJerusalemHebrewDate();

    if (this.lastWDay !== jerusalemTime.wDays && this.latitude !== undefined || this.isLatitudeCanged) {      
      this.isLatitudeCanged = false;
      this.lastWDay = jerusalemTime.wDays;
    } else {
      return;
    }

    this.seasions = YearSeasons.getSeasions(jerusalemTime.years);

    const current = ymdsToNumber(getLocalHebrewDate(this.latitude!));

    let last = Number.MAX_VALUE;
    
    let i = 0;
    for (const key of this.seasonsKeys) {
      if (Object.prototype.hasOwnProperty.call(this.seasions, key)) {
        let seasonObj = <IFormatedHebrewDateTime>(<any>this.seasions)[key];
        const seasson = ymdsToNumber(seasonObj.hebrewDate);
        
        if (current < seasson && last < current) {
          seasonObj.className = "selected";
          this.selectedSeason = seasonObj;
          this.selectesSeasonName = YearSeasons.seasonsNames[i];
        } else {
          seasonObj.className = "not";
        }

        last = seasson;
               
      }
      i++;
    }
    
  }

}
