import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.css']
})
export class SeasionsComponent implements OnInit, OnDestroy {
  header = "";

  headerSubscription!: Subscription;
  constructor(private headerTitle: HeaderTitleService,
    private cdr: ChangeDetectorRef) { }
  ngOnDestroy(): void {
    this.headerSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.headerSubscription = this.headerTitle.headerTitile.subscribe((title: string) =>{
      this.header = title;
      this.cdr.detectChanges();
    });
  }

}
