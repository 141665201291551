import { Component, OnDestroy, OnInit } from '@angular/core';
import { getDateFromDaysPast, getNextMolad, intDivideRemain, Mollad, roundAndRemain, TimeAccessor, YMDs } from 'src/app/classes/hebrew-calculator';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { converJerusalemToLocal, getHoursDiffFromJerusalem, gethoursTimeAccesor, jerusalemLatitude } from 'src/app/utils/hebrew-location';
import { getFormatedHebrewDateTime, getMonthString, IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-molad',
  templateUrl: './mollad.component.html',
  styleUrls: ['./mollad.component.css']
})
export class MolladComponent implements OnInit, OnDestroy {
  hebrewDateMolad: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  hebrewDateMoladNext: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  sofBircatHaLevana: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  hebrewDateMoladLocal: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  sofBircatHaLevanaLocal: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  
  montName!: string;
  nextMontName!: string;
  address: string = '';
  isError = false;
  latitude: number | undefined;
  jerusalemLatitude = jerusalemLatitude;
  lastWDay = -1;
  isLatitudeCanged = false;

  constructor(private datetimeNotify: DatetimeNotifyService) { }
  ngOnDestroy(): void {
    this.datetimeNotify.hebrewDateTime.unsubscribe();
  }

  ngOnInit(): void {
    this.datetimeNotify.hebrewDateTime.subscribe((hebrewDate: YMDs) => {

      if (hebrewDate.wDays !== this.lastWDay && this.latitude !== undefined || this.isLatitudeCanged) {
        this.lastWDay = hebrewDate.wDays;
        this.isLatitudeCanged = false;
      } else {
        return;
      }

      const mollad = new Mollad(hebrewDate.years, hebrewDate.months);
      this.montName = getMonthString(mollad.month, mollad.isleapYear!);
      const molYmds = getDateFromDaysPast(mollad.baharadToMollad);
      this.hebrewDateMolad = getFormatedHebrewDateTime(molYmds);
      const sof = mollad.baharadToMollad.plus(new TimeAccessor(14, 18, 396, 38), false);
      this.sofBircatHaLevana = getFormatedHebrewDateTime(getDateFromDaysPast(sof));


      let locTa = converJerusalemToLocal(mollad.baharadToMollad, this.latitude!);
      let locSofTa = converJerusalemToLocal(sof, this.latitude!);;

      this.hebrewDateMoladLocal = getFormatedHebrewDateTime(getDateFromDaysPast(locTa));
      this.sofBircatHaLevanaLocal = getFormatedHebrewDateTime(getDateFromDaysPast(locSofTa));

      const nextMollad = getNextMolad(mollad);
      this.nextMontName = getMonthString(nextMollad.month, nextMollad.isleapYear!);
      this.hebrewDateMoladNext = getFormatedHebrewDateTime(getDateFromDaysPast(nextMollad.baharadToMollad));

     });
  }

  onAddress(address: string) {
    this.address = address;
  }

  onError(isError: boolean) {
    this.isError = isError;
  }

  onLatitude(latitude: number) {
    if (this.latitude !== latitude) {
      this.isLatitudeCanged =true;
    }
    this.latitude = latitude;
  }

}
