import { Component, Input, OnInit } from '@angular/core';
import { IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-season-forecast',
  templateUrl: './season-forecast.component.html',
  styleUrls: ['./season-forecast.component.css']
})
export class SeasonForecastComponent implements OnInit {
  @Input() season!: IFormatedHebrewDateTime;
  @Input() latitude!: number;
  constructor() { }

  ngOnInit(): void {
  }

}
