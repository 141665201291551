import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { YMDs } from 'src/app/classes/hebrew-calculator';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { getDateString, getFormatedHebrewDateTime, IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-heb-datetime',
  templateUrl: './heb-datetime.component.html',
  styleUrls: ['./heb-datetime.component.css']
})
export class HebDatetimeComponent implements OnInit, OnDestroy {
  date!: Date;
  hebrewDate: IFormatedHebrewDateTime = {
    hebrewDate: new YMDs(),
    wDayName: "",
    monthName: "",
    monthDayName: "",
    minutsHalakim: ""
  };

  dateString!: string;
  @Input() place!: string;
  constructor(private datetimeNotify: DatetimeNotifyService) { }

  ngOnDestroy(): void {
    this.datetimeNotify.date.unsubscribe();
    this.datetimeNotify.hebrewDateTime.unsubscribe();
  }

  ngOnInit(): void {
    this.datetimeNotify.date.subscribe((date: Date) => {
      this.date = date;
      this.dateString = getDateString(date);
    });
    this.datetimeNotify.hebrewDateTime.subscribe((hebrewDate: YMDs) => {
      this.hebrewDate = getFormatedHebrewDateTime(hebrewDate);
     });
  }
}
