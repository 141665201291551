import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(private metaService: Meta) { }

  updateDescription(description: string) {
    this.metaService.updateTag({name: "description", content: description}, "name=description");
  }
}
