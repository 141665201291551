<div>
    <h5 class="text-center">כוכב השעה</h5>
    <p class="text-center">על פי בעל הרוקח הרב אלעזר מגרמייזא</p>
    <app-week-day-night     
    [weekNightDay]="selectedDay"
    [hebrewDate]="hebrewDate"
    ></app-week-day-night>
    <h5 class="text-center">ימי ושעות השבוע</h5>
    <app-week-day-night
    *ngFor="let weekDay of weekNightsDays;"
    [weekNightDay]="weekDay"
    [hebrewDate]="hebrewDate"
    ></app-week-day-night>
</div>

