import { converJerusalemToLocal, jerusalemLatitude } from "../utils/hebrew-location";
import { getFormatedHebrewDateTime, IFormatedHebrewDateTime } from "../utils/hebrew-units-converters";
import { DaysPast, getDateFromDaysPast, intDivideRemain, Mollad, TimeAccessor, YMDs } from "./hebrew-calculator";

export interface ISeasons {
    tamuz: IFormatedHebrewDateTime;
    tishrei: IFormatedHebrewDateTime;
    tevet: IFormatedHebrewDateTime;
    nissan: IFormatedHebrewDateTime;
    tamuzNext: IFormatedHebrewDateTime;
    tishreiNext: IFormatedHebrewDateTime;
}

export class YearSeasons {

    static empty(): ISeasons {
        let s = getFormatedHebrewDateTime(getDateFromDaysPast(YearSeasons.firstSeasonNissan));
        s.className = "";
        return {
            tamuz: s,
            tishrei: s,
            tevet: s,
            nissan: s,
            tamuzNext: s,
            tishreiNext: s           
        }
    }

    static readonly yearPeriod = new TimeAccessor(365, 6);
    static readonly seasionPeriod = new TimeAccessor(91, 7, 540);
    static readonly firstSeasonNissan = YearSeasons.getFirstNissan();

    static getFirstNissan(): TimeAccessor {
        const firstNissan = (new Mollad(1, 7)).baharadToMollad;
        const seasoneErlier = new TimeAccessor(7, 9, 642);
        const firstSeasonNissan = firstNissan.minus(seasoneErlier);
        return firstSeasonNissan;
    }

    static getSeasions(year: number):ISeasons {
        let theFirstNissan = this.firstSeasonNissan.plus(this.yearPeriod.multiply(year - 2, false), false);

        const tamuz = theFirstNissan.plus(this.seasionPeriod, false);
        const tishrei = tamuz.plus(this.seasionPeriod, false);
        const tevet = tishrei.plus(this.seasionPeriod, false);
        const nissan = tevet.plus(this.seasionPeriod, false);
        const tamuzNext = nissan.plus(this.seasionPeriod, false);
        const tishreiNext = tamuzNext.plus(this.seasionPeriod, false);
        return {
            tamuz: getFormatedHebrewDateTime(getDateFromDaysPast(tamuz)),
            tishrei: getFormatedHebrewDateTime(getDateFromDaysPast(tishrei)),
            tevet: getFormatedHebrewDateTime(getDateFromDaysPast(tevet)),
            nissan: getFormatedHebrewDateTime(getDateFromDaysPast(nissan)),
            tamuzNext: getFormatedHebrewDateTime(getDateFromDaysPast(tamuzNext)),
            tishreiNext: getFormatedHebrewDateTime(getDateFromDaysPast(tishreiNext))
        };
    }

    static getSeasionsArray(year: number): IFormatedHebrewDateTime[] {
        const seasions = YearSeasons.getSeasions(year);
        let arr = [];
        arr.push(seasions.tamuz);
        arr.push(seasions.tishrei);
        arr.push(seasions.tevet);
        arr.push(seasions.nissan);
        arr.push(seasions.tamuzNext);
        arr.push(seasions.tishreiNext);

        for (let index = 0; index < arr.length; index++) {
            let s = arr[index];
            s.name = YearSeasons.seasonsNames[index];
        }

        return arr;
    }

    static readonly seasonsNames = ["תמוז","תשרי","טבת","ניסן","תמוז","תשרי"]
    
}
