<app-address-latitude (isErrorEvent)="onError($event)"
    (addressEvent)="onAddress($event)"
    (latitudeEvent)="onLatitude($event)"></app-address-latitude>

<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <h4 dir="rtl" class="text-center">מולד {{montName}} {{address}}</h4>
    </div>
</div>

<app-heb-units [hebrewDate]="hebrewDateMoladLocal" [latitude]="latitude"></app-heb-units>

<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <h4 dir="rtl" class="text-center">סוף זמן ברכת הלבנה {{address}}</h4>
    </div>
</div>

<app-heb-units [hebrewDate]="sofBircatHaLevanaLocal" [latitude]="latitude"></app-heb-units>



<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <h4 dir="rtl" class="text-center">מולד {{montName}} ירושלים</h4>
    </div>
</div>


<app-heb-units [hebrewDate]="hebrewDateMolad" [latitude]="jerusalemLatitude"></app-heb-units>

<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <h4 dir="rtl" class="text-center">סוף זמן ברכת הלבנה ירושלים</h4>
    </div>
</div>

<app-heb-units [hebrewDate]="sofBircatHaLevana" [latitude]="jerusalemLatitude"></app-heb-units>


<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <h4 dir="rtl" class="text-center">מולד הבא {{nextMontName}} ירושלים</h4>
    </div>
</div>

<app-heb-units [hebrewDate]="hebrewDateMoladNext" [latitude]="jerusalemLatitude"></app-heb-units>
