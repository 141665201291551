import { Component, Input, OnInit } from '@angular/core';

export interface IWeekHour {
  hourCharacteristics: string;
  birthCharacter?: string;
  sequentialHour: number;
  sequentialLetter: string;
  localWatch?: string;
  className?: string;
}

export interface WeekNightDay {
  daySequence: number;
  dayString: string;
  night: IWeekHour[];
  day: IWeekHour[];
}

@Component({
  selector: 'app-week-hour',
  templateUrl: './week-hour.component.html',
  styleUrls: ['./week-hour.component.css']
})
export class WeekHourComponent implements OnInit {
  @Input() weekHour: IWeekHour = {
    hourCharacteristics:  "",
    sequentialHour: 0,
    sequentialLetter: " ",
    localWatch: "  ",
    className: "notSelected",    
  };
  constructor() { }

  ngOnInit(): void {
  }

}
