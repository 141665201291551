<div class="row">
    <div class="col-sm-0 col-md-1"></div>
    <div class="col-sm-12 col-md-10">
        <h3 dir="rtl" class="text-center">זמני ירושלים</h3>
        <a dir="rtl" routerLink="/jerusalem-datetime"><p class="btn-outline-primary">זמני המולדות וסוף זמן קידוש הלבנה תלוים ומיוחסים לזמן ירושלים. דהיינו מה תהיה השעה בשעון שאני משתמש כאשר יארע הארוע המדובר בירושלים.</p></a>
    </div>
</div>
<app-jerusalem-datetime [className]="''"></app-jerusalem-datetime>
<div class="row">
    <div class="col-sm-0  col-md-1"></div>
    <div class="col-sm-12  col-md-10">
        <h3 dir="rtl" class="text-center">זמן מקומי</h3>
        <a dir="rtl" routerLink="/ruling-planets"><p class="btn-outline-primary">זמן מקומי מתיחס לענין התקופות שליטת כוכבי השעה וההלכות הקשורים בזמנים אלה. וכן השפעות על נושאים רבים בחיי האדם ובתופעות טבע.</p></a>
    </div>
</div>
<app-local-datetime></app-local-datetime>   
