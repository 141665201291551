<div class="footer-div">
    <div class="row">
        <div class="col-4">
            <h6 dir="rtl" class="text-center">כוכבים ומזלות</h6>            
            <a routerLink="/ruling-planets"><p class="text-center">כוכבי השעה</p></a>
            <a routerLink="/ruling-planets/week-days"><p class="text-center">הנולד בשבוע</p></a>
            <a routerLink="/ruling-planets/week-hours"><p class="text-center">שעות השבוע</p></a>            
        </div>
        <div class="col-4">
            <h6 dir="rtl" class="text-center">מולדות וזמני ירושלים</h6>   
            <a routerLink="/jerusalem-datetime"><p class="text-center">מולד וסוף זמן ברכת הלבנה</p></a>
        </div>
        <div class="col-4">
            <h6 dir="rtl" class="text-center">אודות</h6>   
            <a routerLink="/about"><p class="text-center">אודות</p></a>
            <a href="http://kiddushhachodesh.net" target="_blank"><p class="text-center">קידוש החודש פרקים ו - י</p></a>
            <a href="https://kiddushhodesh.com" target="_blank"><p class="text-center">קידוש החודש פרקים יא - יט</p></a>
            <a href="https://kinuim.web.app" target="_blank"><p class="text-center">טעמי הכנוים</p></a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-4">
            <h6 dir="rtl" class="text-center">לידה</h6>   
            <a routerLink="/find-planet-zodiac"><p class="text-center">מציאת שעת הלידה ע"י תאריך ושם</p></a>
        </div>
        <div class="col-4">
            <h6 dir="rtl" class="text-center">תקופות</h6>   
            <a routerLink="/seasions"><p class="text-center">זמני איסור שתית מים</p></a>
            <a routerLink="/seasions/forecast"><p class="text-center">תחזית התקופות</p></a>
        </div>
        <div class="col-4">
        </div>
    </div>
    
    <p class="text-center">The entirety of this site is protected by copyright &copy; 2020  Hillel Skolnik הלל סקולניק</p>
</div>
