import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomeComponent } from './home/home.component';
import { DaysHoursComponent } from './location-datetime/days-hours/days-hours.component';
import { JerusalemDatetimeComponent } from './location-datetime/jerusalem-datetime/jerusalem-datetime.component';
import { MolladComponent } from './location-datetime/jerusalem-datetime/mollad/mollad.component';
import { PlanetsHoursComponent } from './location-datetime/local-datetime/planets-hours/planets-hours.component';
import { WeekDaysComponent } from './location-datetime/local-datetime/week-days/week-days.component';
import { WeekHoursComponent } from './location-datetime/local-datetime/week-hours/week-hours.component';
import { OverviewComponent } from './location-datetime/overview/overview.component';
import { SeasionWaterComponent } from './location-datetime/seasions/season-latitude/season-water/seasion-water.component';
import { SeasionsComponent } from './location-datetime/seasions/seasons.component';
import { SeasonLatitudeComponent } from './location-datetime/seasions/season-latitude/season-latitude.component';
import { SeasonsForecastComponent } from './location-datetime/seasions/season-latitude/seasions-forecast/seasons-forecast.component';
import { FindPlanetZodiacComponent } from './find-planet-zodiac/find-planet-zodiac.component';

const routes: Routes = [
  {path: '', component: HomeComponent, children: [
    {path: '', component: OverviewComponent}    
  ]},
  {path: 'ruling-planets', component: DaysHoursComponent, children: [
    {path: '', component: PlanetsHoursComponent},
    {path: 'week-days', component: WeekDaysComponent},
    {path: 'week-hours', component: WeekHoursComponent}
  ]},
  {path: 'jerusalem-datetime', component: JerusalemDatetimeComponent, children: [
    {path: '', component: MolladComponent}
  ]},
  {path: 'seasons', component: SeasionsComponent, children: [
    { path: '', component: SeasonLatitudeComponent, children: [
      {path: '', component: SeasionWaterComponent},
      {path: 'forecast', component: SeasonsForecastComponent}
    ]}
  ]},
  {path: "find-planet-zodiac", component: FindPlanetZodiacComponent},
  {path: 'about', component: AboutComponent},
  {path: 'error', component: ErrorPageComponent},
  {path: '**', redirectTo: 'error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
