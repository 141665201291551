<div class="framediv main-div">
  <div class="row navdiv">
      <div class="col-sm-0 col-md-1"></div>
      <div class="col-sm-12 col-md-10">
          <ul class="nav nav-pills justify-content-center">
              <li class="nav-item">
                <a class="nav-link" 
                routerLink="/ruling-planets"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">כוכבי השעה</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" 
                routerLink="/ruling-planets/week-days"
                routerLinkActive="active">הנולד בשבוע</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" 
                routerLink="/ruling-planets/week-hours"
                routerLinkActive="active">שעות השבוע</a>
              </li>
            </ul>    
      </div>    
  </div>
  <div class="row">
    <div class="col-sm-0 col-md-1"></div>
    <div class="col-sm-12 col-md-10">
      <app-local-watch></app-local-watch>
      <app-local-datetime></app-local-datetime>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
