import { Component, Input, OnInit } from '@angular/core';
import { IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

interface IEffect {
  effect: string;
  className: string;
}

interface IPlanetEffect {
  name: string;
  effects: IEffect[];
}

@Component({
  selector: 'app-planet-effect',
  templateUrl: './planet-effect.component.html',
  styleUrls: ['./planet-effect.component.css']
})
export class PlanetEffectComponent implements OnInit {
  private _season!: IFormatedHebrewDateTime;
  @Input() set season (season: IFormatedHebrewDateTime) {
    this._season = season;
    this.setPlanetEffect();
  }
  get season(): IFormatedHebrewDateTime {
    return this._season;
  }

  effect!: IPlanetEffect;

  constructor() { }

  ngOnInit(): void {
  }

  setPlanetEffect() {
    let weekDay = this._season.hebrewDate.wDays;
    if (weekDay === 0) {
      weekDay = 7;
    }
    weekDay--;

    const weekHour = weekDay * 24 + this._season.hebrewDate.dayRemain.timeUnit.hours;
    const planetIdx = (weekHour + 5) % 7;
    let planet = this.planets[planetIdx];
    if (planetIdx === 3) {
      const halakim = this._season.hebrewDate.dayRemain.timeUnit.halakim;
      if (halakim < 540) {
        planet.effects[0].className = "selected";
        planet.effects[1].className = "none";
      } else {
        planet.effects[1].className = "selected";
        planet.effects[0].className = "none";        
      }
    }

    this.effect = planet;
  }

  readonly planets: IPlanetEffect[] = [
    {
      name: "שבתאי",
      effects: [
        {
          effect: "בקיץ חום, ובחורף צינה וירבו גשמים (בעל הרוקח).",
          className: "selected"
        }
      ]
    },
    {
      name: "צדק",
      effects: [
        {
          effect: "בחמה רוח סערה ובצינה רוח גדולה וחזק (ברייתא דשמואל הקטן) כולו קור ושלג וגשמים (בעל הרוקח).",
          className: "selected"
        }
      ]
    },
    {
      name: "מאדים",
      effects: [
        {
          effect: "חום וירדו גשמים ויהיה כולו בלא צינה (בעל הרוקח).",
          className: "selected"
        }
      ]
    },
    {
      name: "חמה",
      effects: [
        {
          effect: "אם נפלה תקופה בתחילת חמה כל התקופה יהיה חום (בעל הרוקח).",
          className: "none"
        },
        {
          effect: "בחצי שעה של חמה חצייה יהיה בינונית (בעל הרוקח).",
          className: "none"
        }
      ]
    },
    {
      name: "נוגה",
      effects: [
        {
          effect: "ירבו הגשמים (בעל הרוקח).",
          className: "selected"
        }
      ]
    },
    {
      name: "כוכב",
      effects: [
        {
          effect: "כולו חום (בעל הרוקח).",
          className: "selected"
        }
      ]
    },
    {
      name: "לבנה",
      effects: [
        {
          effect: "כולו קור (בעל הרוקח).",
          className: "selected"
        }
      ]
    }
  ];

}
