import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { LocalTimeService } from 'src/app/services/local-time.service';
import { getLocalHebrewDate, getLocalTime } from 'src/app/utils/hebrew-location';

@Component({
  selector: 'app-local-datetime',
  templateUrl: './local-datetime.component.html',
  styleUrls: ['./local-datetime.component.css'],
  providers: [LocalTimeService]
})
export class LocalDatetimeComponent implements OnInit {
  address: string = '';
  isError = false;
  latitude: number = 0;
  
  constructor(private datetimeNotify: DatetimeNotifyService) { }

  ngOnInit(): void {
  }

  onAddress(address: string) {
    this.address = address;
  }

  onError(isError: boolean) {
    this.isError = isError;
  }

  onLatitude(latitude: number) {
    this.latitude = latitude;
    this.datetimeNotify.setDate(getLocalTime(latitude));
    this.datetimeNotify.setHebrewDate(getLocalHebrewDate(latitude));    

  }

}
