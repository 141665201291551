<div>
    <div class="hour-planet">
        <h5 class="text-center">יום השבוע</h5>
        <p class="text-center">על פי בעל הרוקח הרב אלעזר מגרמייזא</p>
        <app-week-day [weekDay]="theweekDay"
        [hebrewDate]="hebrewDate"></app-week-day>
    </div>
</div>
<div>
    <h5 class="text-center">ימי השבוע</h5>    
    <app-week-day
    *ngFor="let weekDay of weekDays;"
    [weekDay]="weekDay"
    [hebrewDate]="hebrewDate"
    ></app-week-day>
</div>

