import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DatetimeNotifyService } from 'src/app/services/datetime-notify.service';
import { getJerusalemHebrewDate, getJerusalemTime } from 'src/app/utils/hebrew-location';

@Component({
  selector: 'app-jerusalem-datetime',
  templateUrl: './jerusalem-datetime.component.html',
  styleUrls: ['./jerusalem-datetime.component.css'],
  providers: [DatetimeNotifyService]
})
export class JerusalemDatetimeComponent implements OnInit, OnDestroy {
  location = "ירושלים";
  interval!: number;
  @Input() className = "main-div";
  constructor(private datetimeNotify: DatetimeNotifyService,
    private titleService: Title) { }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }    
  }

  ngOnInit(): void {
    this.titleService.setTitle("מולדות וזמני ירושלים");
    this.interval = setInterval( () => {
    
      this.datetimeNotify.setDate(getJerusalemTime());
      this.datetimeNotify.setHebrewDate(getJerusalemHebrewDate());      
           
    }, 1000) as any;
  }

}
