import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';


interface Feature{
  id: string,
  type: string,
  place_type: string[],
  relevance: number,
  properties: {
      wikidata: string
  },
  text: string,
  place_name: string,
  bbox: number[],
  center: number[],
  geometry: {
      type: string,
      coordinates: number[]
  }  
}

interface FeatureCollection{
  type: string,
  query: string[],
  features: Feature[]
}

@Injectable({
  providedIn: 'root'
})
export class LocalTimeService {
  latetude = new Subject<number>();
  isError = new Subject<boolean>();
  addres = new Subject<string>();
  maps: Map<string, number> = new Map<string, number>();

  constructor(private http: HttpClient) { }

  getLatitude(address:string){
    this.addres.next(address);
    if (this.maps.has(address)) {
      return this.ExecuteData(this.maps.get(address) as number);
    }
    var url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=pk.eyJ1IjoiaGlsbGVscyIsImEiOiJja2h0NWd4NjIxd3p2MnVrNnllaDJzcG1pIn0.bcFDWC5GdB3n7yCrxPdiOw&limit=1`;

    this.http.get<FeatureCollection>(url).subscribe(features =>{
      if (features.features.length === 0) {
        return this.isError.next(true);
      }
      this.maps.set(address, features.features[0].center[0]);
      this.ExecuteData(features.features[0].center[0]);
    }, error => {
      this.isError.next(true);
    });
  }


  private ExecuteData(latetude: number) {
    this.latetude.next(latetude);
    this.isError.next(false);
  }
}
