import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {
  headerTitile = new Subject<string>();
  constructor() { }

  setHeaderTitle(title: string) {
    this.headerTitile.next(title);
  }
}
