<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th class="day">כוכב היום</th>
                    <th class="hour">כוכב השעה</th>
                    <th class="night">כוכב הלילה</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="day">{{dayPlanet}}</td>
                    <td [class]="hourPlanetClass">{{hourPlanetName}}</td>
                    <td class="night">{{nightPlanet}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
