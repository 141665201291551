import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMonths]'
})
export class MonthsDirective {
  @Input('appMonths') set months(m: number) {
    switch (m) {
      case 1:
        this.renderer.createText('תישרי');
        break;
      case 2:
        this.renderer.createText('חשון');
        break;
       case 3:
        this.renderer.setValue(this.ref.nativeElement, 'כסלו');
        break;
       case 3:
        this.renderer.createText('טבת');
        break;
  
      default:
        break;
    }
  };
  constructor(private renderer: Renderer2, private ref: ElementRef) { }

}
