<tr>
    <td class="watch" colspan="3">{{weekHour.localWatch}}</td>
    <td class="sequentialLetter">{{weekHour.sequentialLetter}}</td>
    <td [class]="weekHour.className">שעה</td>
</tr>
<tr>
    <td dir="rtl" class="hourCharacteristics" colspan="4">{{weekHour.hourCharacteristics}}</td>
    <td dir="rtl" class="hour-right">מאפיני השעה</td>
    <br *ngIf="weekHour.birthCharacter === undefined">
</tr>
<tr *ngIf="weekHour.birthCharacter !== undefined">
    <td dir="rtl" class="birthCharacter" colspan="4">{{weekHour.birthCharacter}}</td>
    <td dir="rtl" class="birth-rhight">הנולד בה</td>
    <br>
</tr>

