<div>
    <div class="hour-planet">
        <h3 class="text-center">כוכב השעה</h3>
        <app-planet [planet]="rulingPlanet"></app-planet>
    </div>
</div>
<div class="planets">
    <h3 class="text-center">שבעת הכוכבים</h3>
    <app-planet
    *ngFor="let p of planets;"
    [planet]="p"
    ></app-planet>
</div>
