import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { getDateFromDaysPast, ymdsToNumber } from 'src/app/classes/hebrew-calculator';
import { YearSeasons } from 'src/app/classes/season';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { LocalTimeService } from 'src/app/services/local-time.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { getJerusalemHebrewDate, getLocalHebrewDate } from 'src/app/utils/hebrew-location';
import { getFormatedHebrewDateTime, IFormatedHebrewDateTime } from 'src/app/utils/hebrew-units-converters';

@Component({
  selector: 'app-seasons-forecast',
  templateUrl: './seasons-forecast.component.html',
  styleUrls: ['./seasons-forecast.component.css']
})
export class SeasonsForecastComponent implements OnInit, OnDestroy {
  address: string = '';
  isError = false;
  latitude!: number;
  lastWDay = -1;
  isLatitudeCanged = false;

  seasions = YearSeasons.getSeasionsArray(1);
  selectedSeason = getFormatedHebrewDateTime(getDateFromDaysPast(YearSeasons.firstSeasonNissan));
  selectesSeasonName!: string;


  private latSubscription!: Subscription;
  private isErrorSubscription!: Subscription;
  private addressSubscription!: Subscription;

  constructor(private titleService: Title,
    private metaService: MetaTagsService,
    private headerTitle: HeaderTitleService,
    private localTime: LocalTimeService) { }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.headerTitle.setHeaderTitle("תחזית מזג אוויר תקופתי");
    this.titleService.setTitle("תחזית מזג אוויר תקופתי");
    this.metaService.updateDescription("תחזית מזג אוויר תקופתי על פי כוכב השעה השולט בראש התקופה.");

    this.latSubscription = this.localTime.latetude.subscribe((lat: number) =>{
      this.isError = false;
      if (this.latitude !== lat) {
        this.isLatitudeCanged =true;      
      }
        
      this.latitude = lat;

      this.setDate();
    });

    this.isErrorSubscription = this.localTime.isError.subscribe((isError: boolean) => {
      this.isError = isError;      
    });

    this.addressSubscription = this.localTime.addres.subscribe((address: string) => {
      this.address = address;
    });
  }

  setDate() {
    const jerusalemTime = getJerusalemHebrewDate();

    if (this.lastWDay !== jerusalemTime.wDays && this.latitude !== undefined || this.isLatitudeCanged) {      
      this.isLatitudeCanged = false;
      this.lastWDay = jerusalemTime.wDays;
    } else {
      return;
    }

    this.seasions = YearSeasons.getSeasionsArray(jerusalemTime.years);

    const current = ymdsToNumber(getLocalHebrewDate(this.latitude!));

    let last = Number.MAX_VALUE;
    let lastObj: IFormatedHebrewDateTime | undefined;

    for (let i = 0; i < this.seasions.length; i++) {
      const seasonObj = this.seasions[i];
      const seasson = ymdsToNumber(seasonObj.hebrewDate);
      seasonObj.name = YearSeasons.seasonsNames[i];
      
      if (current < seasson && last < current && lastObj !== undefined) {
        lastObj!.className = "selected";
        this.selectedSeason = lastObj!;
        // this.selectesSeasonName = YearSeasons.seasonsNames[i];
      } else {
        seasonObj.className = "not";
      }

      lastObj = seasonObj;
      last = seasson;
    }

  }

}
