import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DaysPast, getDateFromDaysPast, TimeAccessor, YMDs } from '../classes/hebrew-calculator';
import { HebrewCalenderService } from '../services/hebrew-calender.service';
import { LocalTimeService } from '../services/local-time.service';
import { getLocalHebrewDate } from '../utils/hebrew-location';
import { getFormatedHebrewDateTime, IFormatedHebrewDateTime } from '../utils/hebrew-units-converters';

@Component({
  selector: 'app-hebrew-calender',
  templateUrl: './hebrew-calender.component.html',
  styleUrls: ['./hebrew-calender.component.css']
})
export class HebrewCalenderComponent implements OnInit {
  address: string = '';
  isError = false;
  latitude: number | undefined;
  lastWDay = -1;
  isLatitudeCanged = false;
  isFirstTime = true;
  hebrewDate = getFormatedHebrewDateTime(new YMDs());
  lastDaysToAdd = 0;



  private latSubscription!: Subscription;
  private isErrorSubscription!: Subscription;
  private addressSubscription!: Subscription;

  constructor(private hebrewCalender: HebrewCalenderService,
    private latitudeService: LocalTimeService) { }

  ngOnInit(): void {    
  
    this.latSubscription = this.latitudeService.latetude.subscribe((lat: number) =>{
      this.isError = false;
      if (this.latitude !== lat) {
        this.isLatitudeCanged =true;      
      }
        
      this.latitude = lat;
      this.hebrewCalender.latitude = lat;

      if (this.isFirstTime) {
        this.isFirstTime = false;
        this.hebrewCalender.setHebrewDate(getLocalHebrewDate(this.latitude!));
        this.hebrewDate = getFormatedHebrewDateTime(this.hebrewCalender.hebrewYMDs);
      }
      else {
        this.setDate();
      }      

      
    });

    this.isErrorSubscription = this.latitudeService.isError.subscribe((isError: boolean) => {
      this.isError = isError;      
    });

    this.addressSubscription = this.latitudeService.addres.subscribe((address: string) => {
      this.address = address;
    });
  }

  setDate() {
    if (!this.isLatitudeCanged)  {      
      return;
    }
    this.isLatitudeCanged = false;
    let hebrewDate = getLocalHebrewDate(this.latitude!);

    const daysPasy = new DaysPast(hebrewDate);
    const toAdd = Math.abs(this.lastDaysToAdd);
    let ta: TimeAccessor;
    if (this.lastDaysToAdd > 0) {
      ta = daysPasy.daysPastTimeAccessor.plus(new TimeAccessor(toAdd), false);
      
    } else {
      ta = daysPasy.daysPastTimeAccessor.minus(new TimeAccessor(toAdd));
    }

    this.hebrewDate = getFormatedHebrewDateTime(getDateFromDaysPast(ta));
    this.hebrewCalender.setHebrewDate(this.hebrewDate.hebrewDate);

  }

  addDays(days: number) {
    this.lastDaysToAdd += days;
    const daysPasy = new DaysPast(this.hebrewDate.hebrewDate);
    const toAdd = Math.abs(days);
    let ta: TimeAccessor;
    if (days > 0) {
      ta = daysPasy.daysPastTimeAccessor.plus(new TimeAccessor(toAdd), false);
      
    } else {
      ta = daysPasy.daysPastTimeAccessor.minus(new TimeAccessor(toAdd));
    }

    this.hebrewDate = getFormatedHebrewDateTime(getDateFromDaysPast(ta));
    this.hebrewCalender.setHebrewDate(this.hebrewDate.hebrewDate);
  }



  plusYear( ) {
    this.addDays(365);
  }
  plus10( ) {
    this.addDays(10 * 365);
  }
  plusMonth( ) {
    this.addDays(30);
  }
  plusDay() {
    this.addDays(1);

  }

  minusYear() {
    this.addDays(-365);
  }
  minus10() {
    this.addDays(-10 * 365);
  }
  minusMonth() {
    this.addDays(-30);
    
  }
  minusDay() {
    this.addDays(-1);
    
  }



}
