import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-watch',
  templateUrl: './local-watch.component.html',
  styleUrls: ['./local-watch.component.css']
})
export class LocalWatchComponent implements OnInit, OnDestroy {

  interval!: number;
  localTimeString!: string;
  
  constructor() { }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {
    this.interval = setInterval( () => {
      this.localTimeString = this.getDateString(new Date());
    }, 1000) as any;
  }


  getDateString(date: Date): string {
    return `${('0'+date.getHours()).slice(-2)}:${('0'+date.getMinutes()).slice(-2)}:${('0'+date.getSeconds()).slice(-2) }`;
  }

}
