<div class="row">
    <div class="col-sm-0 col-md-2 col-0"></div>
    <div class="col-sm-12 col-md-8 col-12">
        <div class="row">
            <table class="table-sm table-bordered">
                <thead>
                    <tr>
                        <th class="text-center" colspan="5">{{weekNightDay.dayString}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="list"  (click)="onNightClick()" *ngIf="weekNightDay.night.length > 0">
                        <td dir="rtl" class="night" colspan="5">ליל {{weekNightDay.dayString}}</td>
                    </tr>
                    <div *ngIf="isNightOpen">
                        <app-week-hour *ngFor="let h of weekNightDay.night"
                            [weekHour]="h"></app-week-hour>
                    </div>
                                            
                    <tr class="list"  (click)="onDayClick()"  *ngIf="weekNightDay.day.length > 0">
                        <td dir="rtl" class="day" colspan="5">יום {{weekNightDay.dayString}}</td>
                    </tr>
                    <div *ngIf="isDayOpen">
                        <app-week-hour *ngFor="let h of weekNightDay.day"
                            [weekHour]="h"></app-week-hour>
                    </div>
                </tbody>
            </table>
        </div>
    </div>
</div>

