import { intDivideRemain, YearTypesAndCycles, YMDs } from "../classes/hebrew-calculator";

const getDateString = (date: Date): string => {
    return `${('0'+date.getHours()).slice(-2)}:${('0'+date.getMinutes()).slice(-2)}:${('0'+date.getSeconds()).slice(-2) }`;
}

const isDST = (d: Date): boolean =>{
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset(); 

}

const getMonthDay = (mDay: number): string =>{

    switch (mDay) {
      case 1:        
        return "א";
      case 2:
        return "ב";
      case 3:        
        return "ג";
      case 4:
        return "ד";
      case 5:        
        return "ה";
      case 6:
        return "ו";
      case 7:        
        return "ז";
      case 8:
        return "ח";
      case 9:        
        return "ט";
      case 10:
        return "י";
      case 11:        
        return "יא";
      case 12:
        return "יב";
      case 13:        
        return "יג";
      case 14:
        return "יד";
      case 15:        
        return "טו";
      case 16:
        return "טז";
      case 17:        
        return "יז";
      case 18:
        return "יח";
      case 19:        
        return "יט";
      case 20:
        return "כ";
      case 21:        
        return "כא";
      case 22:
        return "כב";
      case 23:        
        return "כג";
      case 24:
        return "כד";
      case 25:        
        return "כה";
      case 26:
        return "כו";
      case 27:        
        return "כז";
      case 28:
        return "כח";
      case 29:        
        return "כט";
      case 30:
        return "ל";
    
      default:
        return mDay.toString();
    }
}

const getMonthString = (mDay: number, isLeap: boolean):string => {
    if (isLeap) {
      switch (mDay) {
        case 1:        
          return "תשרי";
        case 2:        
          return "חשון";
        case 3:        
          return "כסלו";
        case 4:        
          return "טבת";
        case 5:        
          return "שבט";
        case 6:        
          return "אדר א";
        case 7:        
          return "אדר ב";
        case 8:        
          return "ניסן";
        case 9:        
          return "אייר";
        case 10:        
          return "סיון";
        case 11:        
          return "תמוז";
        case 12:        
          return "אב";
        case 13:        
          return "אלול";
      
        default:
          return mDay.toString();
      }
      
    } else {
      switch (mDay) {
        case 1:        
          return "תשרי";
        case 2:        
          return "חשון";
        case 3:        
          return "כסלו";
        case 4:        
          return "טבת";
        case 5:        
          return "שבט";
        case 6:        
          return "אדר";
        case 7:        
          return "ניסן";
        case 8:        
          return "אייר";
        case 9:        
          return "סיון";
        case 10:        
          return "תמוז";
        case 11:        
          return "אב";
        case 12:        
          return "אלול";
      
        default:
          return mDay.toString();
      }
      
    }
}

const getWeekDayString = (wDay: number): string => {
    switch (wDay) {
      case 0:        
        return "שבת";
      case 1:        
        return "ראשון";
      case 2:        
        return "שני";
      case 3:        
        return "שלישי";
      case 4:        
        return "רביעי";
      case 5:        
        return "חמישי";
      case 6:        
        return "שישי";
   
      default:
        return wDay.toString();
    }
}

const getRulingName = (num: number): string => {
  switch (num) {
    case 0:
      return "שבתאי";
    case 1:
      return "צדק";
    case 2:
      return "מאדים";
    case 3:
      return "חמה";
    case 4:
      return "נגה";
    case 5:
        return "כוכב";
    case 6:
        return "לבנה";
              
    default:
      return num.toString();
  }
}

const getMinutsFromHalakim = (h: number): string => {
  const hm = intDivideRemain(h, 18);

  return `ד-${('0'+hm.divided).slice(-2)}:ח-${('0'+hm.remain).slice(-2)}`;
}

interface IFormatedHebrewDateTime {
    hebrewDate: YMDs;
    wDayName: string;
    monthName: string;
    monthDayName: string;
    minutsHalakim: string;
    className?: string;
    name?: string;
}

const getFormatedHebrewDateTime = (hebrewDate: YMDs): IFormatedHebrewDateTime => {
    let isLeap = (new YearTypesAndCycles(hebrewDate.years)).isleapYear!;
    return {
        hebrewDate, 
        wDayName: getWeekDayString(hebrewDate.wDays),
        monthName: getMonthString(hebrewDate.months, isLeap),
        monthDayName: getMonthDay(hebrewDate.mDays),
        minutsHalakim: getMinutsFromHalakim(hebrewDate.dayRemain.timeUnit.halakim)
    };
}

export {
    getDateString,
    getMonthDay,
    getMonthString,
    getWeekDayString,
    getFormatedHebrewDateTime,
    getRulingName,
    IFormatedHebrewDateTime,
    isDST
};
