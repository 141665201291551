<div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th dir="rtl">{{effect.name}}</th>
                    <th dir="rtl">כוכב השולט בראש התקופה</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of effect.effects">
                    <td dir="rtl" colspan="2" [class]="e.className">{{e.effect}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

